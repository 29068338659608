import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import Card from '../../Cards/Card';
import Blockquote from '../../Blockquote';
import IconButton from '../../Buttons/IconButton/IconButton';

const BackgroundOverlay = styled.div`
  position: fixed;
  inset: 0px;
  opacity: 0.5;
  background-color: #000;
  z-index: 4;
`;

const StyledCard = styled(Card)`
  z-index: 5;
  right: 0;

  .extract-modal__card__cancel-button {
    right: 0;
    top: 0;
  }

  .card-body {
    overflow-y: auto;
    max-height: 800px;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1) !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.standard.primary.font} !important;
    border-radius: 20px !important;
    border: 0 !important;
  }
`;

const StyledFormGroup = styled(Form.Group)`
  input:focus {
    box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.standard.light.accent};
  }

  small {
    white-space: nowrap;
  }

  .detail-limit {
    color: ${({ theme }) => theme.standard.light.accent};
  }

  .licensed-data {
    line-height: 1.1rem;
  }

  .invalid-feedback {
    display: block;
    color: #ffcb6b;
  }

  button {
    border-radius: 50%;
  }
`;

const StyledBlockquote = styled(({ $hasError = false, theme, ...rest }) => <Blockquote {...rest} />)`
  ${({ $hasError, theme }) => {
    return `
      background-color: #fff;
      border-color: ${$hasError ? theme.standard.danger.colour : theme.standard.success.colour};
    `;
  }};
`;

const StyledIconButton = styled(IconButton)`
  background-color: #f2f2f2;
  color: #4e4e4e;
  font-size: 1.5rem;
  height: 40px;
  width: 40px;

  span {
    width: 100%;
    height: 100%;
  }
`;

export { BackgroundOverlay, StyledCard, StyledFormGroup, StyledBlockquote, StyledIconButton };
