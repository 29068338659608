import React, { useState } from 'react';
import { ThemeProvider, withTheme } from 'styled-components';
import { Modal, Form, Row } from 'react-bootstrap';
import { themes } from '../../shared';
import { Loader } from '..';
import { Button } from '../Buttons/Button';
import { ErrorMessage } from '../Message/PresetMessages';
import { DISCLAIMER, HORIZONTAL, RADIO } from './constants';
import { StyledExportModal, StyledForm } from './styled-components';
import { OptionType, QuestionType, ExportModalType, GenericFormType } from './types';

export const ExportModal = ({
  title,
  error,
  showModal,
  isLoading,
  showDisclaimer,
  disclaimerOptions,
  handleSubmit,
  closeModal,
  options,
  theme,
  footerDetails,
  showCustomSubmitPage,
  CustomSubmitPage,
  ...props
}: ExportModalType): JSX.Element => {
  const setDisclaimerOptions = (values: Array<OptionType>): GenericFormType => {
    return values.reduce((previousValue: GenericFormType, currentValue: OptionType) => {
      return { ...previousValue, [currentValue.key]: currentValue.value };
    }, {});
  };

  const setFormOptions = (questions: Array<QuestionType>): GenericFormType => {
    let formValues = {};
    questions.forEach((question: QuestionType) => {
      const { values, selectAll, type } = question;
      values.forEach((value: OptionType) => {
        const { name, secondaryOptions } = value;

        if ((type === 'radio' && value.default) || type === 'checkbox' || type === 'text') {
          formValues = { ...formValues, [name]: selectAll ? true : value.value };
        }

        if (secondaryOptions) {
          secondaryOptions.forEach((option: OptionType) => {
            if (option.default) {
              formValues = { ...formValues, [option.name]: option.value };
            }
          });
        }
      });
    });
    return formValues;
  };

  const { isError, errorMessage } = error;
  const [formState, setFormState] = useState<GenericFormType>(setFormOptions(options));
  const [disclaimerState, setDisclaimerState] = useState<GenericFormType>(setDisclaimerOptions(disclaimerOptions));
  // To show timestamp on filename. Removed colon, not allowed for filename format
  const currentDateTime = new Date();
  const currentIsoDateTime = new Date(currentDateTime.getTime() - currentDateTime.getTimezoneOffset() * 60000)
    .toISOString()
    .replace(/:/g, '')
    .slice(0, -5);

  const handleChange = (
    key: string,
    target: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    disclaimer?: boolean,
    validation?: (target: any) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
    appendTimestamp?: boolean,
  ) => {
    if (disclaimer) {
      setDisclaimerState((f) => ({
        ...f,
        [key]: target.checked,
      }));
    } else if (appendTimestamp) {
      const targetValue = `${target.value}_${currentIsoDateTime}`;
      setFormState((f) => ({
        ...f,
        [key]: target.type === 'text' ? targetValue : target.value,
      }));
      validation && validation(target);
    } else {
      setFormState((f) => ({
        ...f,
        [key]: target.type === 'checkbox' ? target.checked : target.value,
      }));
      validation && validation(target);
    }
  };

  const selectAll = (select: boolean, values: Array<OptionType>) => {
    values.forEach((value: OptionType) => {
      const { name } = value;
      setFormState((f) => ({
        ...f,
        [name]: select,
      }));
    });
  };

  const isDisabled = () => {
    const isValidated = isError === false || Object.values(error).every((value) => value === '');
    const requiredFields = options.filter((option) => option.required).map((option) => option.values[0].name);
    const fields = requiredFields.map((field: string) => !!formState[field]);
    return (
      !Object.values(fields).every(Boolean) ||
      (showDisclaimer && !Object.values(disclaimerState).every(Boolean)) ||
      !isValidated
    );
  };

  const resetForm = () => {
    setFormState(setFormOptions(options));
    setDisclaimerState(setDisclaimerOptions(disclaimerOptions));
  };

  const getFormGroupHeader = (index: number, option: QuestionType, disclaimer?: boolean) => {
    const { type, values, question, subText, required, inputCharacterCount } = option;
    const inputValue = formState[values[0].name]; // if target type is string, there's just one object in values array
    const inputValueCount = () => {
      if (typeof inputValue === 'string' && inputValue.length <= 0) return 0;
      else return typeof inputValue === 'string' && inputValue.length - 18;
    };
    return (
      <Row className="mb-3 px-3 form-row w-100">
        {index + 1}. {question}
        {disclaimer || required ? <span className="aterisk mx-1">*</span> : null}
        {inputCharacterCount && type === 'text' ? (
          <small className="ml-auto text-muted text-right">
            {inputValueCount()}/{inputCharacterCount}
          </small>
        ) : null}
        {subText && <Form.Text className="disclaimer-muted text-muted mb-3 mt-3">{subText}</Form.Text>}
      </Row>
    );
  };

  const getSelectButtons = (values: Array<OptionType>) => {
    return (
      <>
        <button
          type="button"
          id="selectAll"
          key="selectAll_key"
          className="btn btn-link mb-3"
          onClick={() => selectAll(true, values)}
        >
          Select all
        </button>
        <button
          type="button"
          id="selectAll"
          key="unselectAll_key"
          className="btn btn-link mb-3"
          onClick={() => selectAll(false, values)}
        >
          Unselect all
        </button>
      </>
    );
  };

  const getInputOption = (
    type: string,
    name: string,
    value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    label: string,
    disclaimer?: boolean,
    parentKey?: string,
    validation?: (target: any) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
    details?: JSX.Element,
    appendTimestamp?: boolean,
    inputCharacterCount?: number,
  ) => {
    const checked: { [key: string]: boolean | string } = {
      radio: disclaimer ? disclaimerState[name] === value : formState[name] === value,
      checkbox: disclaimer ? disclaimerState[name] : formState[name],
    };

    const TextInput: JSX.Element = (
      <>
        <Form.Control
          id={name + value}
          autoComplete="off"
          type={type}
          isInvalid={error[name] || false}
          name={name}
          maxLength={inputCharacterCount || 999}
          key={`${label}_option_key`}
          onChange={(e) => handleChange(name, e.target, disclaimer, validation, appendTimestamp)}
        />
        {appendTimestamp ? (
          <Form.Text muted className="text-right ml-auto">
            <div>Timestamp will be automatically added to the filename: </div>
            <div>
              {formState[name].toString().slice(0, -currentIsoDateTime.length)}_{currentIsoDateTime}.xlsx{' '}
            </div>
          </Form.Text>
        ) : null}
        <Form.Control.Feedback type="invalid">{error[name]}</Form.Control.Feedback>
        {details}
      </>
    );

    const RadioCheckboxInput: JSX.Element = (
      <Form.Control
        id={name + value}
        type={type}
        name={name}
        value={value}
        key={`${label}_${value}_${name}_option_key`}
        checked={checked[type] as boolean}
        disabled={parentKey && !formState[parentKey]}
        onChange={(e) => handleChange(name, e.target, disclaimer)}
      />
    );

    return (
      <React.Fragment key={`${label}_${value}_${name}_form_control_key`}>
        {type === 'text' ? TextInput : RadioCheckboxInput}
        {label && (
          <Form.Label htmlFor={name + value} className={`px-2 mb-0 ${disclaimer ? 'disclaimer ' : 'default'}`}>
            {label}
          </Form.Label>
        )}
      </React.Fragment>
    );
  };

  const getInputGroup = (option: QuestionType, disclaimer?: boolean) => {
    const { type, values, alignment, validation, details, inputCharacterCount, appendTimestamp } = option;

    return values.map((item: OptionType) => {
      return (
        <React.Fragment key={`${item.name}_${item.value}_input_group_key`}>
          <Row style={{ width: alignment === HORIZONTAL ? '50%' : '100%' }} className="px-3 mb-2 form-row">
            {getInputOption(
              type,
              item.name,
              item.value,
              item.label,
              disclaimer,
              null,
              validation,
              details,
              appendTimestamp,
              inputCharacterCount,
            )}
            {item.secondaryOptions && (
              <div className="secondaryOptions">
                {item.secondaryOptions.map((secondItem: OptionType) => {
                  return getInputOption(RADIO, secondItem.name, secondItem.value, secondItem.label, false, item.name);
                })}
              </div>
            )}
          </Row>
        </React.Fragment>
      );
    });
  };

  const getFormGroup = (option: QuestionType, index: number, disclaimer?: boolean) => {
    const { values, question } = option;
    return (
      <Form.Group key={question} as={Row} className={disclaimer ? 'p-4 disclaimer' : 'p-4 mb-3'} noGutters>
        {getFormGroupHeader(index, option, disclaimer)}
        {option.selectAll && !disclaimer && getSelectButtons(values)}
        {getInputGroup(option, disclaimer)}
      </Form.Group>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledExportModal show={showModal} {...props} centered scrollable>
        <Modal.Header className="pt-4">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {showCustomSubmitPage && CustomSubmitPage ? (
          <Modal.Body>{CustomSubmitPage}</Modal.Body>
        ) : (
          <>
            <Modal.Body>
              {isError && <ErrorMessage details={errorMessage || 'Something went wrong please try again!'} />}
              {isLoading && <Loader showLoadingText={false} size="lg" centered />}
              {!isLoading && !isError && (
                <StyledForm onSubmit={(e: React.FormEvent) => e.preventDefault()} id="export-form">
                  {options.map((option: QuestionType, index: number) => {
                    return getFormGroup(option, index);
                  })}
                  {showDisclaimer &&
                    getFormGroup(
                      { ...DISCLAIMER, values: disclaimerOptions || DISCLAIMER.values },
                      options.length,
                      true,
                    )}
                </StyledForm>
              )}
            </Modal.Body>
            <Modal.Footer className={`mt-0 justify-content-start ${footerDetails ? 'mb-0 pb-0' : 'mb-3'}`}>
              {!isLoading && !isError && (
                <>
                  <Button
                    size="md"
                    id="submit-button"
                    type="submit"
                    variant="primary"
                    disabled={isDisabled()}
                    onClick={() => {
                      handleSubmit(formState);
                      resetForm();
                    }}
                  >
                    Export
                  </Button>
                  <Button
                    id="cancel-button"
                    className="clear-button"
                    size="md"
                    variation="light"
                    onClick={() => {
                      closeModal();
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Modal.Footer>
            {footerDetails ? (
              <Modal.Footer className="mt-0 pt-2 mb-3 justify-content-start">{footerDetails}</Modal.Footer>
            ) : null}
          </>
        )}
      </StyledExportModal>
    </ThemeProvider>
  );
};

ExportModal.defaultProps = {
  title: 'Export',
  showDisclaimer: true,
  disclaimerOptions: DISCLAIMER.values,
  theme: themes.pallets.mpac,
  showCustomSubmitPage: false,
};

export default withTheme(ExportModal);
