import React, { createContext, useContext, useMemo } from 'react';
import styled from 'styled-components';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface ItemID {
  id: UniqueIdentifier;
}

interface Context {
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  listeners: undefined,
  ref() {},
});

export function SortableItem({ children, id }: PropsWithChildren<ItemID>): JSX.Element {
  const { isDragging, listeners, setNodeRef, setActivatorNodeRef, transform } = useSortable({
    id,
  });
  const context = useMemo(
    () => ({
      listeners,
      ref: setActivatorNodeRef,
    }),
    [listeners, setActivatorNodeRef],
  );
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    listStyle: 'none',
  };

  return (
    <SortableItemContext.Provider value={context}>
      <li className="d-flex my-1 align-items-center" ref={setNodeRef} style={style}>
        {children}
      </li>
    </SortableItemContext.Provider>
  );
}

export const DragHandle = ({ disabled }: { disabled?: boolean }): JSX.Element => {
  const { listeners, ref } = useContext(SortableItemContext);

  return (
    <StyledDragHandle $disabled={disabled} {...(disabled ? undefined : listeners)} ref={disabled ? undefined : ref}>
      <svg viewBox="0 0 20 20" width="20">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z" />
      </svg>
    </StyledDragHandle>
  );
};

const StyledDragHandle = styled.button<{ $disabled?: boolean }>`
  display: flex;
  width: 12px;
  padding: 2px 12px 2px 12px;
  justify-content: center;
  touch-action: none;
  cursor: move !important;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed !important' : 'move !important')};

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  :focus-visible {
    box-shadow: 0 0px 0px 2px #4c9ffe;
  }

  svg {
    overflow: visible;
    fill: #919eab;
  }
`;
