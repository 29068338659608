import React from 'react';
import { withTheme } from 'styled-components';
import { themes } from '../../shared';
import Message from './Message';
import { MessageTemplateProps } from './MessageTemplate';

/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
// width ? width : small ? '18rem' : '35rem' - Simple ternary

type InfoMessageType = {
  table?: boolean;
  noBorder?: boolean;
  overrideVariation?: boolean;
} & Pick<
  MessageTemplateProps,
  | 'small'
  | 'details'
  | 'message'
  | 'secondaryMsg'
  | 'theme'
  | 'title'
  | 'className'
  | 'width'
  | 'noBorder'
  | 'variation'
  | 'animateIn'
> &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const InfoMsgPreset = ({
  small,
  message,
  secondaryMsg,
  details,
  table,
  theme,
  variation,
  overrideVariation,
  noBorder,
  title,
  className,
  style,
  width,
  animateIn,
}: InfoMessageType): JSX.Element => {
  return (
    <>
      {table ? (
        <Message.Info
          theme={theme}
          minHeight="auto"
          width="100%"
          small
          hideTitle
          message={message || 'No data found. Please change your search criteria and try again!'}
          secondaryMsg={secondaryMsg}
          details={details}
          borderRadius="xs"
          className={className}
          style={style}
          variation={overrideVariation ? variation : 'basic'}
          overrideVariation
          noBorder={noBorder}
          animateIn={animateIn || false}
        />
      ) : (
        <Message.Info
          theme={theme}
          small={small}
          title={title}
          message={message || 'Please change your search criteria and try again!'}
          secondaryMsg={secondaryMsg}
          details={details}
          width={width ? width : small ? '18rem' : '35rem'}
          minHeight={small ? '125px' : '150px'}
          className={className}
          style={style}
          noBorder={noBorder}
          animateIn={animateIn}
        />
      )}
    </>
  );
};
InfoMsgPreset.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  table: false,
  overrideVariation: false,
  noBorder: false,
};

type PresetMessageType = Pick<
  MessageTemplateProps,
  'small' | 'details' | 'message' | 'secondaryMsg' | 'theme' | 'width' | 'noBorder' | 'animateIn' | 'title'
> &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const WarningMsgPreset = ({
  small,
  message,
  secondaryMsg,
  details,
  theme,
  className,
  title,
  style,
  width,
  noBorder,
  animateIn,
}: PresetMessageType): JSX.Element => (
  <Message.Warning
    theme={theme}
    small={small}
    title={title}
    message={message || 'Nothing found!'}
    secondaryMsg={secondaryMsg}
    details={details}
    width={width ? width : small ? '18rem' : '35rem'}
    minHeight={small ? '125px' : '150px'}
    className={className}
    style={style}
    noBorder={noBorder}
    animateIn={animateIn}
  />
);
WarningMsgPreset.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  noBorder: false, // eslint-disable-line react/default-props-match-prop-types
};

const SuccessMsgPreset = ({
  small,
  message,
  secondaryMsg,
  details,
  theme,
  className,
  title,
  style,
  width,
  noBorder,
  animateIn,
}: PresetMessageType): JSX.Element => (
  <Message.Success
    theme={theme}
    small={small}
    title={title}
    message={message}
    secondaryMsg={secondaryMsg}
    details={details}
    width={width ? width : small ? '18rem' : '35rem'}
    minHeight={small ? '125px' : '150px'}
    className={className}
    style={style}
    noBorder={noBorder}
    animateIn={animateIn}
  />
);
SuccessMsgPreset.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  noBorder: false, // eslint-disable-line react/default-props-match-prop-types
};

const ErrorMsgPreset = ({
  small,
  message,
  secondaryMsg,
  details,
  theme,
  className,
  title,
  style,
  width,
  noBorder,
  animateIn,
}: PresetMessageType): JSX.Element => (
  <Message.Error
    theme={theme}
    small={small}
    title={title}
    message={message || 'Something went wrong!'}
    secondaryMsg={secondaryMsg || 'Please try again later.'}
    details={details}
    width={width ? width : small ? '18rem' : '35rem'}
    minHeight={small ? '125px' : '150px'}
    className={className}
    style={style}
    noBorder={noBorder}
    animateIn={animateIn}
  />
);
ErrorMsgPreset.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  noBorder: false, // eslint-disable-line react/default-props-match-prop-types
};

export const InfoMessage = withTheme(InfoMsgPreset);
export const WarningMessage = withTheme(WarningMsgPreset);
export const SuccessMessage = withTheme(SuccessMsgPreset);
export const ErrorMessage = withTheme(ErrorMsgPreset);
