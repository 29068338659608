import React from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { themes, ThemeProps } from '../shared';

type BlockquoteType = {
  title?: string;
  invert?: boolean;
  className?: string; // eslint-disable-line
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledBlockquote = styled.div<Pick<BlockquoteType, 'theme' | 'variation' | 'invert'>>`
  ${({ theme, variation, invert }) => {
    const { standard, muted } = theme;
    const parseBorderColour = () => {
      const palletType = invert ? muted : standard;
      if (variation === 'basic' || variation === 'light' || variation === 'dark') {
        return palletType[variation].accent;
      }
      return palletType[variation].colour;
    };
    return `
      background-color: ${!invert ? muted[variation].colour : standard[variation].colour};
      border-left: 5px solid ${parseBorderColour()};
      color: ${!invert ? muted[variation].font : standard[variation].font};
      margin: 0.5rem 0;
      text-align: left;
      display: flex;
      position: relative;
      align-items: center;
      border-radius: 3px;`;
  }}
`;

const StyledBlockquoteContent = styled.div`
  padding: 1rem;
  line-height: 1.4;
`;

const StyledBlockquoteTitle = styled.div`
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const Blockquote = ({ title, children, theme, variation, ...props }: BlockquoteType): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <StyledBlockquote variation={variation} {...props}>
        <StyledBlockquoteContent>
          {title ? <StyledBlockquoteTitle>{title}</StyledBlockquoteTitle> : null}
          {children}
        </StyledBlockquoteContent>
      </StyledBlockquote>
    </ThemeProvider>
  );
};

Blockquote.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'basic', // eslint-disable-line react/default-props-match-prop-types
  title: '',
  invert: false,
};

export default withTheme(Blockquote);
