import { Modal, Form } from 'react-bootstrap';
import styled from 'styled-components';

const StyledExportModal = styled(Modal)`
  .modal-header {
    color: ${({ theme }) => theme?.standard.basic.accent};
  }
  .modal-content {
    border: 0px;
    min-height: 600px !important;
    background-color: ${({ theme }) => theme?.standard.light.colour};
  }
  .modal-header,
  .modal-footer,
  .clear-button {
    border: 0px;
  }
  .clear-button {
    span {
      text-decoration: underline;
    }
  }
`;

const StyledForm = styled(Form)`
  .form-group {
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background-color: ${({ theme }) => theme?.standard.basic.colour};
  }

  #selectAll {
    color: #215da1;
    text-decoration: underline;
  }

  input[type='radio'],
  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    cursor: pointer;
  }

  .aterisk {
    color: red;
  }

  .form-label {
    cursor: pointer;
  }

  .form-label.disclaimer {
    font-style: italic;
    font-size: 13px;
    width: 90%;
  }

  .disclaimer > .text-muted {
    width: 100%;
  }

  .form-row {
    align-items: center;
  }

  .heading-row {
    width: 100%;
    font-weight: 600;
    cursor: pointer;
    font-color: #333333;
  }

  .secondaryOptions {
    display: flex;
    margin-left: auto;
    margin-right: 0;
  }
`;

export { StyledExportModal, StyledForm };
