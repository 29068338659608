import React from 'react';
import { StatsCardType } from './types';
import { ThemeProvider, withTheme } from 'styled-components';
import { themes } from '../../shared';
import {
  CardContainer,
  StyledTypeText,
  StyledMainText,
  YearText,
  HighLightTextContainer,
  HighlightStatText,
  HighLightIcon,
  MiddleContainer,
  StyledStatsIcon,
  IconTextContainer,
  IconTextSubHeader,
  MultipleFooterText,
  LongFooterText,
} from './styled-components';

const StatsCard = ({
  cardTypeText,
  cardMainText,
  leftFooterText,
  rightFooterText,
  longFooterText,
  icon,
  CustomIcon,
  cardSize,
  theme,
  opacity,
  hightlightStat,
  yearText,
  iconColor,
  inlineIconText,
  inlineIconTextSubHeader,
  highlightIcon,
  rotateHighLight,
  highlightColor,
  font,
}: StatsCardType): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <CardContainer cardSize={cardSize} font={font}>
        <StyledTypeText cardSize={cardSize}>{cardTypeText}</StyledTypeText>
        <StyledMainText cardSize={cardSize}>{cardMainText}</StyledMainText>
        {yearText && <YearText>{yearText}</YearText>}

        <HighLightTextContainer highlightcolor={highlightColor}>
          {hightlightStat && (
            <HighlightStatText>
              {highlightIcon && <HighLightIcon className={highlightIcon} rotateHighLight={rotateHighLight} />}
              {hightlightStat}
            </HighlightStatText>
          )}
        </HighLightTextContainer>

        <MiddleContainer hightlightStat={hightlightStat}>
          <div>
            {CustomIcon ? (
              <CustomIcon />
            ) : (
              <StyledStatsIcon
                className={icon as string}
                $opacity={opacity}
                $iconColor={iconColor}
                $hightlightStat={hightlightStat}
              />
            )}
          </div>
          <div>
            {inlineIconText && (
              <IconTextContainer>
                {inlineIconText} <IconTextSubHeader>{inlineIconTextSubHeader}</IconTextSubHeader>
              </IconTextContainer>
            )}
          </div>
        </MiddleContainer>
        {(!longFooterText && leftFooterText) || rightFooterText ? (
          <MultipleFooterText cardSize={cardSize}>
            {leftFooterText && (
              <span>
                {leftFooterText[0]}
                <strong>{leftFooterText[1]}</strong>
              </span>
            )}
            {rightFooterText && (
              <span>
                {rightFooterText[0]}
                <strong>{rightFooterText[1]}</strong>
              </span>
            )}
          </MultipleFooterText>
        ) : (
          <LongFooterText> {longFooterText}</LongFooterText>
        )}
      </CardContainer>
    </ThemeProvider>
  );
};

StatsCard.defaultProps = {
  cardSize: 'small',
  theme: themes.pallets.mpac,
  opacity: true,
};

export default withTheme(StatsCard);
