import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ThemeProps } from '../../shared';

const StyledDropdownButton = styled(({ theme, ...rest }) => <DropdownButton {...rest} />)`
  button {
    color: ${({ theme }) => theme.standard.primary.font} !important;
    background-color: ${({ theme }) => theme.standard.primary.colour} !important;
    border-color: ${({ theme }) => theme.standard.primary.colour} !important;
  }

  > .dropdown-menu {
    min-width: 1rem;

    > a:active {
      color: ${({ theme }) => theme.standard.primary.font} !important;
      background-color: ${({ theme }) => theme.standard.primary.colour} !important;
    }
  }
`;

type ItemsPerPageSelectionProps = {
  handleChangeItemsPerPage: (itemsPerPageSelection: number) => void;
  itemsPerPageOptions: Array<number>;
  disabled: boolean;
  currentItemsPerPageSelection: number;
} & ThemeProps;

const ItemsPerPageSelection = ({
  theme,
  handleChangeItemsPerPage,
  itemsPerPageOptions,
  disabled,
  currentItemsPerPageSelection,
}: ItemsPerPageSelectionProps): JSX.Element => {
  return (
    <StyledDropdownButton
      className="mx-2 d-flex h-100"
      size="sm"
      title={currentItemsPerPageSelection}
      disabled={disabled}
    >
      {itemsPerPageOptions.map((itemsPerPage: number) => (
        <Dropdown.Item
          key={`page-size-dropdown-${itemsPerPage}`}
          onClick={() => handleChangeItemsPerPage(itemsPerPage)}
        >
          {itemsPerPage}
        </Dropdown.Item>
      ))}
    </StyledDropdownButton>
  );
};

const ThemedItemsPerPageSelection = withTheme(ItemsPerPageSelection);
export default ThemedItemsPerPageSelection;
