import dark from './darkPallet';
import mono from '../../styles/colours/_monochrome.scss';
import { ThemedSectionType } from './types';

const { primary, secondary, info, success, warning, danger } = dark;

const darkPallet: ThemedSectionType = {
  name: 'dark',
  standard: {
    basic: {
      colour: secondary['ebony-clay'].background,
      font: mono.white,
      accent: danger.jaffa.background,
    },
    primary: {
      colour: primary.rhino.background,
      font: primary.rhino.foreground,
      accent: danger['sandy-brown'].background,
    },
    secondary: {
      colour: secondary['ebony-clay-dark'].background,
      font: secondary['ebony-clay-dark'].foreground,
      accent: '',
    },
    success: {
      colour: success.killarney.background,
      font: success.killarney.foreground,
      accent: '',
    },
    danger: {
      colour: danger['cafe-royale'].background,
      font: danger['cafe-royale'].foreground,
      accent: '',
    },
    warning: {
      colour: warning['yellow-metal'].background,
      font: warning['yellow-metal'].foreground,
      accent: '',
    },
    info: {
      colour: info['rhino-purple'].background,
      font: info['rhino-purple'].foreground,
      accent: '',
    },
    light: {
      colour: mono.light,
      font: mono.black,
      accent: mono.black,
    },
    dark: {
      colour: mono.black,
      font: mono.white,
      accent: mono.black,
    },
  },
  muted: {
    basic: {
      colour: mono.black,
      font: mono.white,
      accent: '',
    },
    primary: {
      colour: primary.wedgewood.background,
      font: primary.wedgewood.foreground,
      accent: '',
    },
    secondary: {
      colour: secondary.lynch.background,
      font: secondary.lynch.foreground,
      accent: '',
    },
    success: {
      colour: success['fruit-salad'].background,
      font: success['fruit-salad'].foreground,
      accent: '',
    },
    danger: {
      colour: danger['fiery-orange'].background,
      font: danger['fiery-orange'].foreground,
      accent: '',
    },
    warning: {
      colour: warning.husk.background,
      font: warning.husk.foreground,
      accent: '',
    },
    info: {
      colour: info['wild-blue-yonder'].background,
      font: info['wild-blue-yonder'].foreground,
      accent: '',
    },
    light: {
      colour: mono.light,
      font: mono.black,
      accent: mono.medium,
    },
    dark: {
      colour: mono.black,
      font: mono.white,
      accent: secondary['oxford-blue'].background,
    },
  },
  inverse: {
    basic: {
      colour: 'transparent',
      font: mono.white,
      accent: danger.jaffa.background,
    },
    primary: {
      colour: 'transparent',
      font: primary.rhino.foreground,
      accent: danger['sandy-brown'].background,
    },
    secondary: {
      colour: 'transparent',
      font: secondary['ebony-clay-dark'].foreground,
      accent: '',
    },
    success: {
      colour: 'transparent',
      font: success.killarney.foreground,
      accent: '',
    },
    danger: {
      colour: 'transparent',
      font: mono.white,
      accent: '',
    },
    warning: {
      colour: 'transparent',
      font: mono.white,
      accent: '',
    },
    info: {
      colour: 'transparent',
      font: info['rhino-purple'].foreground,
      accent: '',
    },
    light: {
      colour: 'transparent',
      font: mono.dark,
      accent: mono.dark,
    },
    dark: {
      colour: mono.black,
      font: mono.white,
      accent: mono.black,
    },
  },
};

export default darkPallet;
