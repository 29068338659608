import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { themes, ThemeProps } from '../../shared';
import { Button } from '../Buttons/Button';
import IconButton, { IconButtonProps } from '../Buttons/IconButton/IconButton';
import Card from '../Cards/Card';
import Divider from '../Divider';
import { SortableList } from './components/SortableList';

type Option = { id: string; value: string; label: string; checked: boolean };

type OptionsMenuProps = {
  options: Option[];
  setOptions: (options: Option[]) => void;
  defaultOptions?: Option[];
  title?: string;
  maximumOptions?: number;
  buttonIconOptions?: IconButtonProps;
  draggable?: boolean;
  radio?: boolean;
  id?: string;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const OptionsMenu = ({
  options,
  setOptions,
  defaultOptions,
  title,
  maximumOptions,
  buttonIconOptions,
  draggable,
  radio,
  id,
  theme,
}: OptionsMenuProps): JSX.Element => {
  const [showOptions, setShowOptions] = useState(false);
  const [localOptions, setLocalOptions] = useState(options);

  const [buttonDistance, setButtonDistance] = useState(null);

  // whenever the options list changes, make sure localOptions are updated
  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  // track window resize to move the menu accordingly
  useEffect(() => {
    const handleResize = () => {
      const optionsButton = document.getElementById(id ? id : 'options-menu-button');
      const distanceToLeft = optionsButton?.getBoundingClientRect().left;
      setButtonDistance(distanceToLeft);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showOptions, id]);

  // radio button option removes maximum options as only 1 option can be selected from the given list
  // eslint-disable-next-line no-param-reassign
  maximumOptions = radio ? undefined : maximumOptions;

  const checkedOptions = localOptions?.filter((key) => key.checked);
  const disabledOption = maximumOptions && checkedOptions && checkedOptions?.length >= maximumOptions;
  return (
    <ThemeProvider theme={theme}>
      <div className="d-flex flex-column position-relative">
        <IconButton
          className="pr-auto"
          id={id ? id : 'options-menu-button'}
          style={{ fontSize: '1.3rem' }}
          outline={showOptions}
          icon="fas fa-cog"
          onClick={() => setShowOptions(!showOptions)}
          {...buttonIconOptions}
        />
        {showOptions ? (
          <OptionsMenuContainer $buttonDistance={buttonDistance}>
            <Card variation="basic" shadow>
              {title ? (
                <Card.Header>
                  <h5>{title}</h5>
                  {maximumOptions ? <small>Maximum selection of {maximumOptions}</small> : null}
                </Card.Header>
              ) : null}
              {!title && maximumOptions ? (
                <Card.Header>
                  <small>Maximum selection of {maximumOptions}</small>
                </Card.Header>
              ) : null}
              <Card.Body className="px-3 py-2" style={{ maxHeight: '320px', overflow: 'auto' }}>
                <UtilityButtonContainer>
                  {defaultOptions ? (
                    <UtilityButton
                      onClick={() => setLocalOptions(defaultOptions)}
                      className="p-0 mr-1"
                      $type="reset"
                      disabled={defaultOptions && JSON.stringify(localOptions) === JSON.stringify(defaultOptions)}
                    >
                      Reset
                    </UtilityButton>
                  ) : (
                    <div />
                  )}
                  <UtilityButton
                    onClick={() => setLocalOptions([...localOptions.map((key) => ({ ...key, checked: false }))])}
                    className="p-0 ml-1"
                    $type="clear"
                    disabled={checkedOptions.length === 0}
                  >
                    Clear
                  </UtilityButton>
                </UtilityButtonContainer>
                <SortableList
                  items={localOptions}
                  onChange={setLocalOptions}
                  renderItem={(item) =>
                    item.checked || !maximumOptions ? (
                      <SortableList.Item id={item.id}>
                        {draggable ? <SortableList.DragHandle /> : null}
                        <MenuOption
                          radio={radio}
                          option={item}
                          localOptions={localOptions}
                          setLocalOptions={setLocalOptions}
                        />
                      </SortableList.Item>
                    ) : null
                  }
                />
                {maximumOptions && checkedOptions.length ? <Divider className="my-2" /> : null}
                {maximumOptions ? (
                  <SortableList
                    items={localOptions}
                    onChange={setLocalOptions}
                    renderItem={(item) =>
                      !item.checked ? (
                        <SortableList.Item id={item.id}>
                          {draggable ? <SortableList.DragHandle disabled /> : null}
                          <MenuOption
                            radio={radio}
                            disabled={disabledOption}
                            option={item}
                            localOptions={localOptions}
                            setLocalOptions={setLocalOptions}
                          />
                        </SortableList.Item>
                      ) : null
                    }
                  />
                ) : null}
              </Card.Body>
              <Card.Footer>
                <div className="d-flex justify-content-between">
                  <Button
                    className="w-100 mr-1"
                    onClick={() => {
                      setOptions(localOptions);
                      setShowOptions(false);
                    }}
                    disabled={checkedOptions.length === 0}
                  >
                    Save
                  </Button>
                  <Button
                    className="w-100 ml-1"
                    outlineOnly
                    variation="danger"
                    onClick={() => {
                      setLocalOptions(options);
                      setShowOptions(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </OptionsMenuContainer>
        ) : null}
      </div>
    </ThemeProvider>
  );
};

const MenuOption = ({
  disabled,
  option,
  localOptions,
  setLocalOptions,
  radio,
}: {
  localOptions: Option[];
  option: {
    id: string;
    value: string;
    label: string;
    checked: boolean;
  };
  setLocalOptions: React.Dispatch<React.SetStateAction<Option[]>>;
  disabled?: boolean;
  radio?: boolean;
}) => {
  return (
    <StyledCheckBox $disabled={disabled}>
      <label className="d-flex align-items-center mb-0">
        <input
          className="mr-2"
          type={radio ? 'radio' : 'checkbox'}
          name={radio ? 'radio' : 'checkbox'}
          value={option.value}
          checked={option.checked}
          disabled={disabled || undefined}
          onChange={() =>
            setLocalOptions([
              ...localOptions.map((localOption) =>
                localOption.value === option.value
                  ? { id: option.id, value: option.value, label: option.label, checked: !option.checked }
                  : { ...localOption, checked: radio ? false : localOption.checked },
              ),
            ])
          }
        />
        {option.label}
      </label>
    </StyledCheckBox>
  );
};

export default withTheme(OptionsMenu);

const OptionsMenuContainer = styled.div<{ $buttonDistance: number }>`
  position: absolute;
  margin-left: ${({ $buttonDistance }) =>
    $buttonDistance === null || $buttonDistance > 240 ? '-240px' : `-${$buttonDistance}px`};
  top: 40px;
  margin-top: 0.5rem;
  width: 280px;
  height: 400px;
  z-index: 1;

  .card-body {
    padding-top: 0 !important;
  }
`;

const StyledCheckBox = styled.div<{ $disabled?: boolean | undefined }>`
  label {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    font-size: 1rem;
    margin-left: 5px;
  }
  input {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    height: 20px;
    min-width: 20px;
    accent-color: ${({ theme }) => theme?.standard.primary.colour};
  }
`;

const UtilityButtonContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 0.25rem 0.25rem 0 0.25rem;
  display: flex;
  justify-content: space-between;
  background-color: white;
`;

const UtilityButton = styled(Button)<{ $type: string }>`
  border: 0;
  background: transparent !important;
  outline: none;
  box-shadow: none;
  font-size: smaller;
  font-weight: bold;
  color: #015ca6 !important;

  :hover,
  :focus {
    box-shadow: none !important;
    color: #015ca6 !important;
  }
`;

OptionsMenu.defaultProps = {
  theme: themes.pallets.mpac,
};
