import mpac from '../../styles/colours/_mpac.scss';

const primaryPallet = {
  endeavour: {
    background: mpac.endeavour,
    foreground: '#fff',
  },
  'science-blue': {
    background: mpac['science-blue'],
    foreground: '#fff',
  },
  denim: {
    background: mpac.denim,
    foreground: '#fff',
  },
  'havelock-blue': {
    background: mpac['havelock-blue'],
    foreground: '#000',
  },
  danube: {
    background: mpac.danube,
    foreground: '#000',
  },
  'chetwode-blue': {
    background: mpac['chetwode-blue'],
    foreground: '#000',
  },
  'regent-st-blue': {
    background: mpac['regent-st-blue'],
    foreground: '#000',
  },
  pindle: {
    background: mpac.pindle,
    foreground: '#000',
  },
  'link-water': {
    background: mpac['link-water'],
    foreground: '#000',
  },
  'link-water-light': {
    background: mpac['link-water-light'],
    foreground: '#000',
  },
  // From UX designer's designs
  'alice-blue': {
    background: mpac['alice-blue'],
    foreground: '#000',
  },
};

const dangerPallet = {
  valencia: {
    background: mpac.valencia,
    foreground: '#fff',
  },
  'flame-pea': {
    background: mpac['flame-pea'],
    foreground: '#000',
  },
  'red-damask': {
    background: mpac['red-damask'],
    foreground: '#000',
  },
  terracotta: {
    background: mpac.terracotta,
    foreground: '#000',
  },
  copperfield: {
    background: mpac.copperfield,
    foreground: '#000',
  },
  'tonys-pink': {
    background: mpac['tonys-pink'],
    foreground: '#000',
  },
  'tonys-pink-light': {
    background: mpac['tonys-pink-light'],
    foreground: '#000',
  },
  'desert-sand': {
    background: mpac['desert-sand'],
    foreground: '#000',
  },
  'desert-sand-light': {
    background: mpac['desert-sand-light'],
    foreground: '#000',
  },
  linen: {
    background: mpac.linen,
    foreground: '#000',
  },
};

const warningPallet = {
  'school-bus-yellow': {
    background: '#fdd900',
    foreground: '#000',
  },
  'bright-sun': {
    background: '#fddc32',
    foreground: '#000',
  },
  mustard: {
    background: '#fedf51',
    foreground: '#000',
  },
  kournikova: {
    background: '#fee26c',
    foreground: '#000',
  },
  salomie: {
    background: '#fee581',
    foreground: '#000',
  },
  'golden-glow': {
    background: '#fee995',
    foreground: '#000',
  },
  'vis-vis': {
    background: '#ffedaa',
    foreground: '#000',
  },
  beeswax: {
    background: '#fef0be',
    foreground: '#000',
  },
  'milk-punch': {
    background: '#fff4d2',
    foreground: '#000',
  },
  'off-yellow': {
    background: '#fef8e6',
    foreground: '#000',
  },
};

const infoPallet = {
  downy: {
    background: mpac.downy,
    foreground: '#000',
  },
  'monte-carlo': {
    background: mpac['monte-carlo'],
    foreground: '#000',
  },
  'monte-carlo-light': {
    background: mpac['monte-carlo-light'],
    foreground: '#000',
  },
  sinbad: {
    background: mpac.sinbad,
    foreground: '#000',
  },
  'aqua-island': {
    background: mpac['aqua-island'],
    foreground: '#000',
  },
  'jagged-ice': {
    background: mpac['jagged-ice'],
    foreground: '#000',
  },
  'jagged-ice-light': {
    background: mpac['jagged-ice-light'],
    foreground: '#000',
  },
  'swans-down': {
    background: mpac['swans-down'],
    foreground: '#000',
  },
  'swans-down-light': {
    background: mpac['swans-down-light'],
    foreground: '#000',
  },
  'aqua-squeeze': {
    background: mpac['aqua-squeeze'],
    foreground: '#000',
  },
};

const successPallet = {
  crete: {
    background: mpac.crete,
    foreground: '#fff',
  },
  pesto: {
    background: mpac.pesto,
    foreground: '#000',
  },
  apple: {
    background: mpac.apple,
    foreground: '#fff',
  },
  asparagus: {
    background: mpac.asparagus,
    foreground: '#000',
  },
  'chelsea-cucumber': {
    background: mpac['chelsea-cucumber'],
    foreground: '#000',
  },
  'green-smoke': {
    background: mpac['green-smoke'],
    foreground: '#000',
  },
  olivine: {
    background: mpac.olivine,
    foreground: '#000',
  },
  'swamp-green': {
    background: mpac['swamp-green'],
    foreground: '#000',
  },
  coriander: {
    background: mpac.coriander,
    foreground: '#000',
  },
  'green-mist': {
    background: mpac['green-mist'],
    foreground: '#000',
  },
  'screamin-green': {
    background: mpac['screamin-green'],
    foreground: '#000',
  },
};

const secondaryPallet = {
  'san-juan': {
    background: mpac['san-juan'],
    foreground: '#fff',
  },
  'blue-bayoux': {
    background: mpac['blue-bayoux'],
    foreground: '#fff',
  },
  'cutty-sark': {
    background: mpac['cutty-sark'],
    foreground: '#fff',
  },
  'slate-gray': {
    background: mpac['slate-gray'],
    foreground: '#000',
  },
  'regent-gray': {
    background: mpac['regent-gray'],
    foreground: '#000',
  },
  'gull-gray': {
    background: mpac['gull-gray'],
    foreground: '#000',
  },
  'tower-gray': {
    background: mpac['tower-gray'],
    foreground: '#000',
  },
  loblolly: {
    background: mpac.loblolly,
    foreground: '#000',
  },
  geyser: {
    background: mpac.geyser,
    foreground: '#000',
  },
  porcelain: {
    background: mpac.porcelain,
    foreground: '#000',
  },
};

const mpacPallet = {
  primary: primaryPallet,
  secondary: secondaryPallet,
  success: successPallet,
  danger: dangerPallet,
  warning: warningPallet,
  info: infoPallet,
};

export default mpacPallet;
