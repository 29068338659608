import React from 'react';
import styled, { css } from 'styled-components';
import Card from '../Cards/Card';
import type { CardProps } from '../Cards/Card';
import mono from '../../styles/colours/_monochrome.scss';
import { shimmer } from './TextRow';

type CardBlockType = {
  height?: string;
} & Omit<CardProps, 'children'> &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledCardBlock = styled(Card)`
  height: ${({ height }: CardBlockType) => height};
  background-color: ${mono.medium};

  /* START: Skeleton Animation - Copied in CardBlock */
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    /* stylelint-disable */
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.8) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    /* stylelint-enable */
    content: '';
    ${() =>
      css`
        animation: ${shimmer} 2s infinite;
      `};
  }
  /* END: Skeleton Animation - Copied in CardBlock */
`;

const CardBlock = (props: CardBlockType): JSX.Element => {
  return (
    <StyledCardBlock variation="basic" {...props}>
      <Card.Body>
        <div />
      </Card.Body>
    </StyledCardBlock>
  );
};

CardBlock.defaultProps = {
  height: 'auto',
};

export default CardBlock;
