import { OptionType } from './types';

const DISCLAIMER_OPTIONS: OptionType[] = [
  {
    value: false,
    key: 'usageClauseAgreement',
    name: 'usageClauseAgreement',
    label:
      'I acknowledge that no unauthorized access, use, modification, duplication, distribution or storage is permitted.',
  },
  {
    value: false,
    key: 'licenseClauseAgreement',
    name: 'licenseClauseAgreement',
    label:
      'I acknowledge that the terms of the Data Sharing and Services Agreement and applicable Product Use Sheets apply.',
  },
  {
    value: false,
    key: 'riskClauseAgreement',
    name: 'riskClauseAgreement',
    label: 'The Institution assumes all risks and liabilities from the use of the Information.',
  },
  {
    value: false,
    key: 'informationClauseAgreement',
    name: 'informationClauseAgreement',
    label:
      'Where personal information appears on the record, I acknowledge that privacy obligations under the Municipal Freedom of Information and Protection of Privacy Act apply.',
  },
];

const DISCLAIMER = {
  question: 'Disclaimer',
  type: 'checkbox',
  subText:
    'Please note that use of this downloaded content is for internal planning and internal operational purposes of your institution only. Some information may be in progress and subject to change.',
  values: DISCLAIMER_OPTIONS,
};

const HORIZONTAL = 'horizontal';

const RADIO = 'radio';

export { DISCLAIMER_OPTIONS, DISCLAIMER, HORIZONTAL, RADIO };
