import React from 'react';
import styled from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';
import { themes, ThemeProps } from '../../shared';

type CardHeaderProps = {
  children: React.ReactNode;
  accent?: boolean;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledCardHeader = styled(({ theme, variation, accent, ...rest }) => <BootstrapCard.Header {...rest} />)`
  ${({ theme, variation, accent }) => {
    return `
      ${variation !== 'basic' ? `background: ${theme.standard[variation].colour}` : ''};
      color: ${accent ? theme.standard[variation].accent : theme.standard[variation].font};
    `;
  }}
`;

const CardHeader = ({ theme, variation, children, accent, ...props }: CardHeaderProps): JSX.Element => (
  <StyledCardHeader theme={theme} variation={variation} accent={accent} {...props}>
    {children}
  </StyledCardHeader>
);
CardHeader.displayName = 'CardHeader';
CardHeader.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  accent: false,
};

export default CardHeader;
