import { useState, useCallback, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCopyClipboard = (resetInterval = 1000): [boolean, (text: any) => void, Promise<unknown>] => {
  const [isCopied, setIsCopied] = useState(false);

  const copyPromise = new Promise((resolve, _) => {
    setTimeout(
      () =>
        resolve(() => {
          return isCopied;
        }),
      resetInterval,
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCopy = useCallback((text: any) => {
    if (typeof text === 'string' || typeof text == 'number') {
      navigator.clipboard.writeText(text.toString()).then(
        () => {
          setIsCopied(true);
        },
        () => {
          setIsCopied(false);
          // eslint-disable-next-line no-console
          console.error(`Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`);
        },
      );
    } else {
      setIsCopied(false);
      // eslint-disable-next-line no-console
      console.error(`Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`);
    }
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied && resetInterval) {
      timeout = setTimeout(() => setIsCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return [isCopied, handleCopy, copyPromise];
};

export default useCopyClipboard;
