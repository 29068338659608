/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/**
 * Converts a number into a short version
 * eg: 1000 -> 1K
 * 1000000 -> 1M
 */

const nFormatter = (num: number | string, digits: number) => {
  const symbols = [
    { value: 1, s: '' },
    { value: 1e3, s: 'K' },
    { value: 1e6, s: 'M' },
    { value: 1e9, s: 'B' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  if (typeof num === 'string') {
    num = parseInt(num);
  }

  let i;
  for (i = symbols.length - 1; i > 0; i--) {
    if (Math.abs(num) >= symbols[i].value) {
      break;
    }
  }
  return (num / symbols[i].value).toFixed(digits).replace(rx, '$1') + symbols[i].s;
};

export default nFormatter;
