import React from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';
import { themes, ThemeProps, constants } from '../../shared';
import mono from '../../styles/colours/_monochrome.scss';
import CardBody from './Body';
import CardHeader from './Header';
import CardHighlight from './Highlight';
import CardFooter from './Footer';
import Loader from '../Loader';
import { InfoMessage, WarningMessage, SuccessMessage, ErrorMessage } from '..';

export type CardProps = {
  active?: boolean; // passed to Body and Highlight
  shadow?: boolean; // Card only
  showBorder?: boolean; // Card only
  styledBorder?: boolean; // Card only
  borderOnly?: boolean; // used with Card and passed to Body and Highlight
  width?: string; // Card only
  borderRadius?: keyof typeof constants.sizes; // used to calculate borderRadiusValue
  borderSize?: keyof typeof constants.sizes; // Card only
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any; // Card only
  loading?: boolean;
  minHeight?: string;
  alertMessage?:
    | {
        type: 'info' | 'warning' | 'success' | 'error' | string;
        message?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
        secondaryMsg?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
        details?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
        small?: boolean;
        noBorder?: boolean;
      }
    | null
    | undefined;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledBootstrapCard = styled(
  ({
    theme,
    variation,
    shadow,
    showBorder,
    active,
    width,
    hasHeader,
    borderOnly,
    borderRadiusValue,
    borderSize,
    styledBorder,
    minHeight,
    ...rest
  }) => <BootstrapCard {...rest} />,
)`
  ${({
    theme,
    variation,
    shadow,
    showBorder,
    width,
    borderOnly,
    hasHeader,
    borderRadiusValue,
    borderSize,
    styledBorder,
    minHeight,
  }) => {
    const parseBorder = () => {
      if (!styledBorder) {
        if (theme.name === 'dark') return mono.white;
        return 'rgba(0,0,0,.125)';
      }
      if (variation === 'basic') {
        if (theme.name === 'dark') return mono.white;
        return 'rgba(0,0,0,.125)';
      }
      if (theme.name === 'dark') {
        return theme.standard.primary.accent;
      }
      return theme.standard[variation].colour;
    };
    const parseBackgroundColour = () => {
      if (hasHeader || borderOnly) {
        return theme.standard.basic.colour;
      }
      return theme.standard[variation].colour;
    };
    const parseBorderSize = () => {
      switch (borderSize) {
        case 'xs':
          return '1px';
        case 'sm':
          return '2px';
        case 'md':
          return '3px';
        case 'lg':
          return '4px';
        case 'xl':
          return '5px';
        default:
          return '1px';
      }
    };
    const adjustedBorderRadius = () => {
      let radius = '2px';
      switch (borderSize) {
        case 'xs':
          radius = '2px';
          break;
        case 'sm':
          radius = '3px';
          break;
        case 'md':
          radius = '4px';
          break;
        case 'lg':
          radius = '5px';
          break;
        case 'xl':
          radius = '6px';
          break;
        default:
          radius = '2px';
          break;
      }
      return `calc(${borderRadiusValue} - ${radius})`;
    };
    return `
      width: ${width};
      box-shadow: ${shadow ? '3px 3px 10px 5px rgba(0, 0, 0, 0.07)' : 'none'};
      background-color: ${parseBackgroundColour()};
      border: ${showBorder ? `${parseBorderSize()} solid ${parseBorder()}` : 0};
      border-radius: ${borderRadiusValue};
      .card-header:first-child, .card-img-top {
        border-top-left-radius: ${adjustedBorderRadius()};
        border-top-right-radius: ${adjustedBorderRadius()};
      }
      .card-img-bottom, .card-footer:last-child  {
        border-bottom-left-radius: ${adjustedBorderRadius()};
        border-bottom-right-radius: ${adjustedBorderRadius()};
      }
      min-height: ${minHeight ? `${minHeight}` : '100px'};
    `;
  }}
`;

const Card = ({
  theme,
  variation,
  children,
  shadow,
  showBorder,
  borderOnly,
  active,
  width,
  borderRadius,
  borderSize,
  styledBorder,
  loading,
  minHeight,
  alertMessage,
  ...props
}: CardProps): JSX.Element => {
  const hasChild = (childrenProps: React.ReactElement | React.ReactElement[], name: string) =>
    React.Children.toArray(childrenProps).findIndex((child: JSX.Element) => child.type.displayName === name);
  const parseBorderRadius = () => {
    switch (borderRadius) {
      case 'xs':
        return '0rem';
      case 'sm':
        return '0.25rem';
      case 'md':
        return '0.5rem';
      case 'lg':
        return '0.75rem';
      case 'xl':
        return '1rem';
      default:
        return '0rem';
    }
  };
  const borderRadiusValue = parseBorderRadius();
  const hasHeader = hasChild(children, 'CardHeader') !== -1;

  const renderChildren = () => {
    const themeProps = {
      theme,
      variation,
    };
    return React.Children.map(children, (child: JSX.Element) => {
      if (child) {
        // Special Case
        // Let Card.Body know if a Header component is nested inside Card
        if (child.type.displayName === 'CardBody') {
          return React.cloneElement(child, {
            hasHeader,
            borderOnly,
            active,
            ...themeProps,
          });
        }
        // Special Case
        // Card.Highlight can be inside Card and/or Card.Body
        if (child.type.displayName === 'WithTheme(CardHighlight)') {
          return React.cloneElement(child, {
            active,
            borderOnly,
            ...themeProps,
          });
        }
        return React.cloneElement(child, { ...themeProps });
      }
      return null;
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderBody = (): JSX.Element | React.FunctionComponentElement<any>[] => {
    if (loading) {
      return (
        <Card.Body>
          <Loader theme={theme} size="md" centered />
        </Card.Body>
      );
    }
    if (alertMessage) {
      let cardBody: JSX.Element;
      switch (alertMessage.type) {
        case 'error':
          cardBody = (
            <ErrorMessage
              theme={theme}
              message={alertMessage.message}
              secondaryMsg={alertMessage.secondaryMsg}
              details={alertMessage.details}
              small={alertMessage.small}
              noBorder={alertMessage.noBorder}
            />
          );
          break;
        case 'warning':
          cardBody = (
            <WarningMessage
              theme={theme}
              message={alertMessage.message}
              secondaryMsg={alertMessage.secondaryMsg}
              details={alertMessage.details}
              small={alertMessage.small}
              noBorder={alertMessage.noBorder}
            />
          );
          break;
        case 'success':
          cardBody = (
            <SuccessMessage
              theme={theme}
              message={alertMessage.message}
              secondaryMsg={alertMessage.secondaryMsg}
              details={alertMessage.details}
              small={alertMessage.small}
              noBorder={alertMessage.noBorder}
            />
          );
          break;
        default:
          cardBody = (
            <InfoMessage
              theme={theme}
              message={alertMessage.message}
              secondaryMsg={alertMessage.secondaryMsg}
              details={alertMessage.details}
              small={alertMessage.small}
              noBorder={alertMessage.noBorder}
            />
          );
          break;
      }
      return <Card.Body>{cardBody}</Card.Body>;
    }
    return renderChildren();
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledBootstrapCard
        variation={variation}
        shadow={shadow}
        width={width}
        showBorder={showBorder}
        borderOnly={borderOnly}
        borderRadiusValue={borderRadiusValue}
        borderSize={borderSize}
        hasHeader={hasHeader}
        styledBorder={styledBorder}
        minHeight={minHeight}
        {...props}
      >
        {renderBody()}
      </StyledBootstrapCard>
    </ThemeProvider>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Highlight = CardHighlight;
Card.Footer = CardFooter;

Card.Img = styled(({ theme, variation, variant, ...rest }) => <BootstrapCard.Img variant={variant} {...rest} />)``;
Card.Img.defaultProps = {
  variant: 'top',
};
Card.Img.displayName = 'CardImage';

Card.ImgOverlay = styled(({ theme, variation, ...rest }) => <BootstrapCard.ImgOverlay {...rest} />)``;
Card.ImgOverlay.displayName = 'CardImgOverlay';

Card.Title = styled(({ theme, variation, ...rest }) => <BootstrapCard.Title {...rest} />)``;
Card.Title.displayName = 'CardTitle';

Card.Subtitle = styled(({ theme, variation, ...rest }) => <BootstrapCard.Subtitle {...rest} />)``;
Card.Subtitle.displayName = 'CardSubtitle';

Card.Text = styled(({ theme, variation, ...rest }) => <BootstrapCard.Text {...rest} />)``;
Card.Text.displayName = 'CardText';

Card.Link = styled(({ theme, variation, ...rest }) => <BootstrapCard.Link {...rest} />)``;
Card.Link.displayName = 'CardLink';

Card.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  width: 'auto',
  shadow: false,
  active: false,
  showBorder: true,
  borderOnly: false,
  borderRadius: 'md',
  borderSize: 'xs',
  styledBorder: false,
  loading: false,
  minHeight: '',
  alertMessage: null,
};

export default withTheme(Card) as React.ForwardRefExoticComponent<
  CardProps & {
    theme?: ThemeProps;
  }
> & {
  Header: typeof CardHeader;
  Body: typeof CardBody;
  Highlight: typeof CardHighlight;
  Footer: typeof BootstrapCard.Footer;
  Img: typeof BootstrapCard.Img;
  ImgOverlay: typeof BootstrapCard.ImgOverlay;
  Title: typeof BootstrapCard.Title;
  Text: typeof BootstrapCard.Text;
  Subtitle: typeof BootstrapCard.Subtitle;
};
