import React from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { themes, ThemeProps } from '../shared';

type HeadingProps = {
  children: React.ReactNode;
  accent?: boolean;
  level?: '1' | '2' | '3' | '4' | '5' | '6';
} & ThemeProps &
  React.HtmlHTMLAttributes<HTMLHeadingElement> &
  React.CSSProperties;

const StyledHeading = styled.div<ThemeProps & Pick<HeadingProps, 'accent'>>`
  ${({ theme, variation, accent }) => {
    const { standard, name } = theme;
    const type = !accent ? 'font' : 'accent';
    const parseColour = () => {
      if (variation !== 'basic') {
        return standard[variation][type];
      }
      if (name === 'light') {
        return standard.basic.font;
      }
      return standard.basic[type];
    };
    return `color: ${parseColour()}`;
  }}
`;

const Heading = ({ children, theme, variation, accent, level, ...props }: HeadingProps): JSX.Element => (
  <ThemeProvider theme={theme}>
    <StyledHeading as={`h${level}` as React.ElementType} accent={accent} variation={variation} {...props}>
      {children}
    </StyledHeading>
  </ThemeProvider>
);

const H1 = ({ children, theme, variation, accent, ...props }: Omit<HeadingProps, 'level'>) => (
  <Heading theme={theme} variation={variation} accent={accent} level="1" {...props}>
    {children}
  </Heading>
);
const H2 = ({ children, theme, variation, accent, ...props }: Omit<HeadingProps, 'level'>) => (
  <Heading theme={theme} variation={variation} accent={accent} level="2" {...props}>
    {children}
  </Heading>
);
const H3 = ({ children, theme, variation, accent, ...props }: Omit<HeadingProps, 'level'>) => (
  <Heading theme={theme} variation={variation} accent={accent} level="3" {...props}>
    {children}
  </Heading>
);
const H4 = ({ children, theme, variation, accent, ...props }: Omit<HeadingProps, 'level'>) => (
  <Heading theme={theme} variation={variation} accent={accent} level="4" {...props}>
    {children}
  </Heading>
);
const H5 = ({ children, theme, variation, accent, ...props }: Omit<HeadingProps, 'level'>) => (
  <Heading theme={theme} variation={variation} accent={accent} level="5" {...props}>
    {children}
  </Heading>
);
const H6 = ({ children, theme, variation, accent, ...props }: Omit<HeadingProps, 'level'>) => (
  <Heading theme={theme} variation={variation} accent={accent} level="6" {...props}>
    {children}
  </Heading>
);

Heading.H1 = withTheme(H1);
Heading.H2 = withTheme(H2);
Heading.H3 = withTheme(H3);
Heading.H4 = withTheme(H4);
Heading.H5 = withTheme(H5);
Heading.H6 = withTheme(H6);

const defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'basic', // eslint-disable-line react/default-props-match-prop-types
  accent: false,
  level: '1',
};
Heading.defaultProps = defaultProps;

export default Heading;
