import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import mono from '../../styles/colours/_monochrome.scss';
import { constants } from '../../shared';

export type TextRowType = {
  length?: keyof typeof constants.sizes;
  lineHeight?: string;
} & React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

export const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const StyledTextRow = styled.div`
  width: ${({ length }: Omit<TextRowType, 'className'>) => {
    if (length === 'xs') return '20%';
    if (length === 'sm') return '40%';
    if (length === 'md') return '60%';
    if (length === 'lg') return '80%';
    return '100%';
  }}; /* stylelint-disable-line */
  height: ${(props) => props.lineHeight};
  margin-top: 0.7em;
  background-color: ${mono.medium};

  /* START: Skeleton Animation - Copied in CardBlock */
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    /* stylelint-disable */
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    /* stylelint-enable */
    content: '';
    ${() =>
      css`
        animation: ${shimmer} 2s infinite;
      `};
  }
  /* END: Skeleton Animation - Copied in CardBlock */
`;

const TextRow = (props: TextRowType): JSX.Element => <StyledTextRow {...props} />;

TextRow.defaultProps = {
  length: 'xl',
  lineHeight: '1em',
};

export default TextRow;
