import mpac from './mpacTheme';
import indigo from './indigoTheme';
import dark from './darkTheme';
// import light from './lightTheme';
import mpacPallet from './mpacPallet';
import indigoPallet from './indigoPallet';
import darkPallet from './darkPallet';
// import lightPallet from './lightPallet';
import type { ThemedSectionType } from './types';

export type { ThemedSectionType }; // re-export for easier import syntax themes.ThemedSectionType

export const ThemeAppearances = {
  basic: 'basic',
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  info: 'info',
  warning: 'warning',
  danger: 'danger',
  light: 'light',
  dark: 'dark',
};
export type PalletType = {
  mpac: ThemedSectionType;
  indigo: ThemedSectionType;
  dark: ThemedSectionType;
  // light: ThemedSectionType;
};

export const pallets: PalletType = {
  mpac,
  indigo,
  dark,
  // light,
};

export const colourPallet = {
  mpac: mpacPallet,
  // light: lightPallet,
  dark: darkPallet,
  indigo: indigoPallet,
};
