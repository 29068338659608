import React from 'react';
import styled from 'styled-components';
import TextRow, { TextRowType } from './TextRow';
import { constants } from '../../shared';

type ImageBlockType = {
  size?: keyof typeof constants.sizes;
  square?: boolean;
} & Omit<TextRowType, 'length' | 'lineHeight'>;

const StyledImageBlock = styled(TextRow)`
  ${({ size, square }: ImageBlockType) => {
    return `
      width: ${constants.sizes[size]}px;
      min-width: ${constants.sizes[size]}px;
      min-height: ${constants.sizes[size]}px;
      border-radius: ${!square ? '50%' : 0}
    `;
  }}
`;

const ImageBlock = ({ ...props }: ImageBlockType): JSX.Element => <StyledImageBlock {...props} />;

ImageBlock.defaultProps = {
  size: 'xl',
  square: false,
};

export default ImageBlock;
