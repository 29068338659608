import React, { HTMLProps, CSSProperties, useCallback, useMemo } from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { themes, ThemeProps } from '../../../shared';
import HoverText from '../../HoverText';

export type IconButtonProps = Pick<
  HTMLProps<HTMLButtonElement>,
  'onClick' | 'className' | 'style' | 'title' | 'aria-label' | 'id' | 'onKeyDown'
> &
  ThemeProps &
  Partial<{
    scale: boolean;
    src: string;
    icon: string;
    stackedIcon: string;
    stackedIconStyle: CSSProperties;
    contentVisible: boolean;
    svg: React.SVGProps<SVGSVGElement>;
    tooltip: { label: React.ReactNode; copy?: boolean };
    type: 'button' | 'submit' | 'reset';
    outline: boolean;
    background: boolean;
    disabled?: boolean;
  }>;

const IconButton = ({
  icon,
  stackedIcon,
  stackedIconStyle,
  src,
  svg,
  contentVisible,
  scale,
  tooltip,
  onClick,
  disabled,
  theme,
  ...buttonProps
}: IconButtonProps) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  const button = useMemo(
    () => (
      <StyledButton aria-label="Icon button" onClick={handleClick} {...buttonProps} $disabled={disabled}>
        {(contentVisible || contentVisible === undefined) && (
          <IconContainer style={buttonProps.style} $scale={scale} $disabled={disabled}>
            <>
              {stackedIcon && (
                <span className="fa-stack">
                  {icon && <span className={`${icon} fa-stack-1x`} aria-label="Bottom Icon" />}
                  <i
                    style={{ ...{ color: '#000', fontSize: '0.4em' }, ...stackedIconStyle }}
                    className={`${stackedIcon} fas fa-stack-1x fa-inverse`}
                    aria-label="Top Icon"
                  />
                </span>
              )}
              {!stackedIcon && <div>{icon && <i className={icon} />} </div>}
              {src && <img src={src} alt={`${src}-icon`} />}
              {svg}
            </>
          </IconContainer>
        )}
      </StyledButton>
    ),
    [handleClick, buttonProps, contentVisible, stackedIcon, scale, icon, stackedIconStyle, src, svg, disabled],
  );

  return (
    <ThemeProvider theme={theme}>
      {tooltip ? (
        <HoverText
          id={`${(icon || src || svg)?.toString()}-tooltip`}
          tooltipCopy={tooltip.copy || false}
          tooltip={tooltip.label || ''}
        >
          {button}
        </HoverText>
      ) : (
        button
      )}
    </ThemeProvider>
  );
};

export const IconButtonGroup = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: CSSProperties;
}): JSX.Element => {
  return <StyledIconGroup style={style || {}}>{children}</StyledIconGroup>;
};

IconButton.Group = IconButtonGroup;

const StyledButton = styled(({ outline, $disabled, theme, variation, background, ...rest }) => (
  <button type="button" {...rest} />
))`
  ${({ outline, $disabled, theme, variation, background }) => {
    const getColour = () => {
      if ($disabled) {
        return `${theme.standard.light.font} !important`;
      }
      if (variation === 'basic') {
        return theme.standard[variation].font;
      }
      return theme.standard[variation].colour;
    };

    const getBackgroundColour = () => {
      if (background) {
        if ($disabled) {
          return `${theme.standard.light.colour} !important`;
        }
        return theme.muted[variation].colour;
      }
      return 'transparent';
    };

    const getOutlineColour = () => {
      if (outline) {
        if ($disabled) {
          return `${theme.standard.light.font} !important`;
        }
        if (variation === 'basic') {
          return theme.standard[variation].font;
        }
        return theme.standard[variation].colour;
      }
      return 'transparent';
    };

    return `
      background-color: ${getBackgroundColour()};
      color: ${getColour()};
      border: 1px solid ${getOutlineColour()};
      font-size: 1.5rem;
      font-size: 1.5rem;
      width: 38px;
      height: 38px;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.1s;
      cursor: ${$disabled ? 'not-allowed !important' : 'pointer'};
      opacity: ${$disabled ? '.5' : '1'};

      :focus {
        outline: transparent;
      }

      :focus-visible {
        box-shadow: inset 0 0 0 3px ${theme.standard[variation].colour};
      }
    `;
  }}
`;

const IconContainer = styled.span<{ $scale: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.1s;

  :hover {
    transform: ${({ $disabled, $scale }) => (!$disabled && $scale ? 'scale(1.3)' : 'none')};
  }
`;

const StyledIconGroup = styled.div`
  display: inline-flex;
  background-color: #f2f2f2;
  padding: 5px;
  border-radius: 0.25rem;
  > * {
    margin-right: 0.5rem;
  }
  *:last-child {
    margin-right: 0;
  }
`;

IconButton.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  outline: true,
  background: true,
  disabled: false,
  style: {
    fontSize: '1.5rem',
    width: '38px',
    height: '38px',
  },
  stackedIconStyle: {
    color: '#000',
    fontSize: '0.4em',
  },
};

IconButtonGroup.defaultProps = {
  style: {
    backgroundColor: '#f2f2f2',
    padding: '5px',
  },
};

export default withTheme(IconButton) as React.ForwardRefExoticComponent<
  IconButtonProps & {
    theme?: ThemeProps;
  }
> & {
  Group: typeof IconButtonGroup;
};
