import { createGlobalStyle } from 'styled-components';
import typography from './typography';

export const headerFontUrl = 'https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap';
export const bodyFontUrl = 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap';

export const GlobalStyles = createGlobalStyle`
  html, body {
    font-family: ${typography.type.body}
  }

  body {
    background-color: unset;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${typography.type.headings}
  }
`;
