import React from 'react';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';
import { constants } from '../shared';

const StyledAvatar = styled(Image)`
  cursor: pointer;
`;

type AvatarType = {
  name: string;
  src: string;
  size?: keyof typeof constants.sizes;
} & React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const Avatar = ({ name, size, ...props }: AvatarType): JSX.Element => {
  return (
    <StyledAvatar height={constants.sizes[size]} width={constants.sizes[size]} alt={name} roundedCircle {...props} />
  );
};

Avatar.defaultProps = {
  size: 'lg',
};

export default Avatar;
