import mpac from './mpacPallet';
import mono from '../../styles/colours/_monochrome.scss';
import { ThemedSectionType } from './types';

const { primary, secondary, info, success, warning, danger } = mpac;

const mpacPallet: ThemedSectionType = {
  name: 'mpac',
  standard: {
    basic: {
      colour: mono.white,
      font: mono.black,
      accent: primary.endeavour.background,
    },
    primary: {
      colour: primary.endeavour.background,
      font: primary.endeavour.foreground,
      accent: success['screamin-green'].background,
    },
    secondary: {
      colour: secondary['blue-bayoux'].background,
      font: secondary['blue-bayoux'].foreground,
      accent: '',
    },
    success: {
      colour: success.apple.background,
      font: success.apple.foreground,
      accent: '',
    },
    danger: {
      colour: danger.valencia.background,
      font: danger.valencia.foreground,
      accent: '',
    },
    warning: {
      colour: warning['bright-sun'].background,
      font: warning['bright-sun'].foreground,
      accent: '',
    },
    info: {
      colour: info.downy.background,
      font: info.downy.foreground,
      accent: '',
    },
    light: {
      colour: mono.light,
      font: mono.black,
      accent: mono.medium,
    },
    dark: {
      colour: mono.black,
      font: mono.white,
      accent: mono.black,
    },
  },
  muted: {
    basic: {
      colour: mono.white,
      font: mono.black,
      accent: '',
    },
    // primary: {
    //   colour: primary['link-water-light'].background,
    //   font: primary['link-water-light'].foreground,
    //   accent: '',
    // },
    primary: {
      colour: primary['alice-blue'].background,
      font: primary['alice-blue'].foreground,
      accent: '',
    },
    secondary: {
      colour: secondary.porcelain.background,
      font: secondary.porcelain.foreground,
      accent: '',
    },
    success: {
      colour: success['green-mist'].background,
      font: success['green-mist'].foreground,
      accent: '',
    },
    danger: {
      colour: danger.linen.background,
      font: danger.linen.foreground,
      accent: '',
    },
    warning: {
      colour: warning['off-yellow'].background,
      font: warning['off-yellow'].foreground,
      accent: '',
    },
    info: {
      colour: info['aqua-squeeze'].background,
      font: info['aqua-squeeze'].foreground,
      accent: '',
    },
    light: {
      colour: mono.light,
      font: mono.black,
      accent: mono.medium,
    },
    dark: {
      colour: secondary['cutty-sark'].background,
      font: secondary['cutty-sark'].foreground,
      accent: mono.black,
    },
  },
  inverse: {
    basic: {
      colour: mono.white,
      font: mono.black,
      accent: primary.endeavour.background,
    },
    primary: {
      colour: mono.white,
      font: primary.endeavour.background,
      accent: '',
    },
    secondary: {
      colour: mono.white,
      font: secondary['blue-bayoux'].background,
      accent: '',
    },
    success: {
      colour: mono.white,
      font: success.apple.background,
      accent: '',
    },
    danger: {
      colour: danger.valencia.foreground,
      font: danger.valencia.background,
      accent: '',
    },
    warning: {
      colour: mono.white,
      font: mono.black,
      accent: warning['bright-sun'].background,
    },
    info: {
      colour: mono.white,
      font: info.downy.background,
      accent: '',
    },
    light: {
      colour: mono.white,
      font: mono.dark,
      accent: mono.dark,
    },
    dark: {
      colour: mono.white,
      font: mono.black,
      accent: mono.black,
    },
  },
};

export default mpacPallet;
