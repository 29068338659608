import React from 'react';
import { Spinner, SpinnerProps, Image } from 'react-bootstrap';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { constants, themes, ThemeProps } from '../shared';
import mono from '../styles/colours/_monochrome.scss';

type LoaderProps = {
  centered?: boolean;
  overlay?: boolean;
  image?: string;
  animation?: 'border' | 'grow';
  showLoadingText?: boolean;
  loadingText?: string;
  inverse?: boolean;
  size?: keyof typeof constants.sizes;
  inline?: boolean;
  spinnerPosition?: 'before' | 'after' | '';
  blur?: number;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledLoader = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  opacity: 1;
  padding: 1em;
  user-select: none;

  ${({
    centered,
    inline,
    spinnerPosition,
    overlay,
    blur,
  }: Pick<LoaderProps, 'centered' | 'inline' | 'spinnerPosition' | 'overlay' | 'blur'>) => {
    if (centered) {
      return `
        display: flex;
        width: 100%;
        min-height: 100%;
        height: auto;
        position: absolute;
        z-index: 1000;
        background-color: ${overlay ? 'rgba(0, 0, 0, 0.85)' : 'transparent'};
        top: 0;
        left: 0;
        backdrop-filter: blur(${blur ? `${blur || 6}` : 0}px);
      `;
    }
    if (inline) {
      return `
        display: inline;
        padding: 0 ${spinnerPosition ? '.5em' : '1.5em'};
        margin: 0;
      `;
    }
    return `
      display: flex;
      z-index: 1000;
      line-height: 1;
    `;
  }}
`;

const StyledSpinner = styled(Spinner)`
  ${({
    theme,
    variation,
    animation,
    size,
    inverse,
  }: Pick<LoaderProps, 'animation' | 'size' | 'inverse'> & ThemeProps) => {
    const getBorderColour = () => {
      if (inverse) {
        return theme.name !== 'dark' ? standard[variation].font : standard[variation].accent;
      }
      return theme.name !== 'dark' ? standard[variation].colour : standard[variation].accent;
    };
    const { standard } = theme;
    const borderColor = getBorderColour();
    const parseSize = () => {
      switch (size) {
        case 'xs':
          return '1rem';
        case 'sm':
          return '2rem';
        case 'md':
          return '3rem';
        case 'lg':
          return '4rem';
      }
      return '5rem';
    };
    const dimensions = parseSize();
    return `
      position: relative;
      max-width: 100%;
      background-color: ${animation === 'border' ? 'transparent' : borderColor};
      vertical-align: baseline;
      border-left-color: ${borderColor};
      border-top-color: ${borderColor};
      border-bottom-color: ${borderColor};
      width: ${dimensions};
      height: ${dimensions};
      border-width: ${size === 'md' || size === 'lg' || size === 'xl' ? '.35em' : '.25em'};
    `;
  }}
`;

const StyledImageSpinner = styled(Image)`
  ${({ size }: Pick<LoaderProps, 'size'>) => {
    // Original image is 800px x 600px / 4:3 ratio
    // 800px === 50 rem - https://nekocalc.com/px-to-rem-converter
    const parseSize = () => {
      switch (size) {
        case 'xs':
          return {
            width: '10rem',
            height: '7.5rem',
          };
        case 'sm':
          return {
            width: '12rem',
            height: '9rem',
          };
        case 'md':
          return {
            width: '14rem',
            height: '10.5rem',
          };
        case 'lg':
          return {
            width: '16rem',
            height: '12rem',
          };
      }
      return {
        width: '18rem',
        height: '13.5rem',
      };
    };
    const dimensions = parseSize();
    return `
      width: ${dimensions.width};
      height: ${dimensions.height};
    `;
  }}
`;

const StyledLoaderText = styled.span`
  ${({ theme, centered, size, overlay }: Pick<LoaderProps, 'theme' | 'centered' | 'size' | 'overlay'>) => {
    return `
      color: ${(centered && overlay) || theme.name === 'dark' ? mono.white : mono.black};
      font-size: ${size === 'lg' ? '1.25rem' : '1rem'};
      margin-top: 8px;
    `;
  }};
`;

const Loader = ({
  centered,
  showLoadingText,
  loadingText,
  animation,
  theme,
  variation,
  inline,
  spinnerPosition,
  inverse,
  size,
  overlay,
  className,
  image,
  blur,
  ...props
}: LoaderProps & Omit<SpinnerProps, 'size' | 'animation'>): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <StyledLoader
        centered={centered}
        overlay={overlay}
        inline={inline}
        spinnerPosition={spinnerPosition}
        blur={blur}
        className={className}
      >
        {image ? (
          // <StyledImageSpinner src={theme.name !== 'dark' ? loaderImg : loaderImgDark} size={size} />
          <StyledImageSpinner src={image} size={size} alt="page loading image" />
        ) : (
          <StyledSpinner
            {...props}
            size={size}
            animation={animation}
            role="status"
            variant={undefined}
            variation={variation}
            $inverse={inverse}
          >
            {showLoadingText ? <span className="sr-only">{loadingText}</span> : null}
          </StyledSpinner>
        )}
        {showLoadingText ? (
          <StyledLoaderText {...props} centered={centered} theme={theme} size={size} overlay={overlay}>
            {loadingText}
          </StyledLoaderText>
        ) : null}
      </StyledLoader>
    </ThemeProvider>
  );
};

Loader.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  image: '',
  animation: 'border',
  size: 'lg',
  centered: false,
  inverse: false,
  overlay: false,
  showLoadingText: false,
  loadingText: 'Loading',
  inline: false,
  spinnerPosition: undefined,
  blur: 0,
};

const ThemedLoader = withTheme(Loader);
ThemedLoader.displayName = 'WithTheme(Loader)';
export default ThemedLoader;
