import React from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap';
import { darken, lighten } from 'polished';
import { themes, ThemeProps } from '../../shared';
import Loader from '../Loader';

const Text = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 100%;
`;

const StyledButton = styled(({ $outlineOnly, ...rest }: { $outlineOnly: boolean }) => <BootstrapButton {...rest} />)`
  ${({ theme, variation, $outlineOnly }: ThemeProps & { $outlineOnly: boolean }) => {
    const { standard, inverse } = theme;
    const baseColours = `
      background-color: ${$outlineOnly ? inverse[variation].colour : standard[variation].colour};
      border-color: ${standard[variation].colour};
      color: ${$outlineOnly ? inverse[variation].font : standard[variation].font};
    `;
    const baseBoxShadow = `${
      theme.name === 'light'
        ? `0 0 0 0.075rem ${lighten(0.1, standard[variation].colour)} !important`
        : `0 0 0 0.075rem ${lighten(0.4, standard[variation].colour)} !important;`
    }`;
    return `
      ${baseColours}
      &:hover {
        background-color: ${darken(0.08, standard[variation].colour)};
        border-color: ${standard[variation].colour};
        color: ${standard[variation].font};
      }
      &:active {
        background-color: ${$outlineOnly ? inverse[variation].colour : standard[variation].colour} !important;
        border-color: ${standard[variation].colour} !important;
        box-shadow: ${baseBoxShadow};
      }
      &:focus {
        background-color: ${$outlineOnly ? inverse[variation].colour : darken(0.05, standard[variation].colour)};
        border-color: ${darken(0.05, standard[variation].colour)};
        color: ${$outlineOnly ? inverse[variation].font : standard[variation].font} !important;
        box-shadow: ${baseBoxShadow};
      }
      &[disabled] {
        ${baseColours}
        cursor: not-allowed;
      }
    `;
  }}
`;

type ButtonType = {
  children: React.ReactNode;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean | ('before' | 'after');
  outlineOnly?: boolean;
} & ThemeProps &
  Omit<ButtonProps, 'size'> &
  React.HTMLAttributes<HTMLButtonElement> &
  React.CSSProperties;

export const Button = ({ children, theme, isLoading, outlineOnly, size, ...props }: ButtonType): JSX.Element => {
  const { variation } = props;
  const parseText = () => {
    if (!isLoading) {
      return <Text>{children}</Text>;
    }
    if (typeof isLoading === 'boolean') {
      return <Loader size="xs" variation={variation} inline animation="border" inverse={!outlineOnly} />;
    }
    return isLoading === 'before' ? (
      <>
        <Loader
          size="xs"
          variation={variation}
          inline
          spinnerPosition={!(typeof isLoading === 'boolean') && isLoading}
          animation="border"
        />
        <Text>{children}</Text>
      </>
    ) : (
      <>
        <Text>{children}</Text>
        <Loader
          size="xs"
          variation={variation}
          inline
          spinnerPosition={!(typeof isLoading === 'boolean') && isLoading}
          animation="border"
        />
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledButton {...props} $outlineOnly={outlineOnly} size={size === 'md' ? undefined : size}>
        {parseText()}
      </StyledButton>
    </ThemeProvider>
  );
};

Button.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  disabled: false,
  size: 'md',
  isLoading: false,
  outlineOnly: false,
};

export default withTheme(Button);
