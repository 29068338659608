import React, { useMemo, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
  Active,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DragHandle, SortableItem } from './SortableItem';
import { SortableOverlay } from './SortableOverlay';

type BaseItem = {
  id: UniqueIdentifier;
};

type SortableListProps<T extends BaseItem> = {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T): React.ReactNode;
};

export const SortableList = <T extends BaseItem>({
  items,
  onChange,
  renderItem,
}: SortableListProps<T>): JSX.Element => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={({ active: activeElement }) => {
        setActive(activeElement);
      }}
      onDragEnd={({ active: activeElement, over }) => {
        if (over && activeItem.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === activeElement.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);
          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        <ul className="p-0 m-0">
          {items.map((item) => (
            <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
    </DndContext>
  );
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
