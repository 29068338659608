import { useMemo, useState } from 'react';

export const PAGE_NUMBER_TYPES = {
  page: 'page',
  prevEllipsis: 'prevEllipsis',
  nextEllipsis: 'nextEllipsis',
};

type PageType = keyof typeof PAGE_NUMBER_TYPES;

export type PageNumbers = {
  type: PageType;
  pageNumber?: number;
};

const INDEX_ONE_ARRAY = 1; // We want an array that starts at 1 no 0
export const OFFSET_ELLIPSIS = 0;

export type UsePaginationProps = {
  currentPage: number;
  totalNumberOfPages: number;
  numberOfPagesToShow: number;
  hideMaxMinPageNumbers?: boolean;
};

const usePagination = ({
  currentPage,
  numberOfPagesToShow: rawNumberOfPagesToShow,
  totalNumberOfPages, // Also last page number
  hideMaxMinPageNumbers,
}: UsePaginationProps): Array<PageNumbers> => {
  const [lowerPageBound, setLowerPageBound] = useState(0);

  const getNewPage = (type: PageType, pageNumber?: number): PageNumbers => ({ type, pageNumber: pageNumber || 0 });

  return useMemo<Array<PageNumbers>>((): Array<PageNumbers> => {
    const numberOfPagesToShow = rawNumberOfPagesToShow <= 0 ? 7 : rawNumberOfPagesToShow;
    const allPages = Array.from(Array(totalNumberOfPages).keys()).map((i) => i + INDEX_ONE_ARRAY);

    let currentPageIsNearBegining = false;
    let currentPageIsNearEnd = false;
    const lowerPagePtr = currentPage - numberOfPagesToShow <= 0;
    const upperPagePtr = currentPage + numberOfPagesToShow - 1 >= totalNumberOfPages;
    if (lowerPagePtr && !upperPagePtr) currentPageIsNearBegining = true;
    if (upperPagePtr) currentPageIsNearEnd = true;

    let activePages = [1];
    if (currentPageIsNearBegining || currentPageIsNearEnd) {
      if (numberOfPagesToShow + OFFSET_ELLIPSIS === allPages.length) {
        activePages = [...allPages]; // Prevent [1, 2, 3, 4, 5, ..., 7]
      } else {
        let calcLowerPageBound = 0;
        if (currentPageIsNearBegining && lowerPageBound !== 0) {
          setLowerPageBound(0);
        }
        if (currentPageIsNearEnd && calcLowerPageBound !== totalNumberOfPages - numberOfPagesToShow) {
          calcLowerPageBound = totalNumberOfPages - numberOfPagesToShow;
          if (calcLowerPageBound < 0) calcLowerPageBound = 0;
          setLowerPageBound(() => calcLowerPageBound);
        }
        activePages = allPages.slice(calcLowerPageBound, calcLowerPageBound + numberOfPagesToShow);
      }
    } else {
      if (currentPage > lowerPageBound + numberOfPagesToShow - OFFSET_ELLIPSIS) {
        setLowerPageBound(() => currentPage - 1);
      }
      if (lowerPageBound >= currentPage) {
        // if (lowerPageBound - currentPage > numberOfPagesToShow) {
        // Jump lower page bound if gap between currentPage is much smaller than lowerPageBound
        setLowerPageBound(() => currentPage - numberOfPagesToShow + OFFSET_ELLIPSIS);
        // } else {
        //   setLowerPageBound(() => lowerPageBound - numberOfPagesToShow + OFFSET_ELLIPSIS);
        // }
      }
      activePages = allPages.slice(lowerPageBound, lowerPageBound + numberOfPagesToShow - OFFSET_ELLIPSIS);
    }

    const allPagesDisplayed = activePages.length === allPages.length || activePages.length + 1 === allPages.length;

    // Remove 1 and last page since they are constants and will be added at the final step
    if (activePages.includes(1)) activePages.shift();
    if (activePages.includes(totalNumberOfPages)) activePages.pop();

    // console.table({
    //   currentPageIsNearBegining,
    //   currentPageIsNearEnd,
    //   lowerPageBound,
    //   currentPage,
    // });

    const pages = [...activePages].map((pageNumber: number): PageNumbers => {
      return {
        type: 'page',
        pageNumber,
      };
    });

    const firstPage =
      !hideMaxMinPageNumbers || currentPage === 1 || currentPageIsNearBegining ? getNewPage('page', 1) : null;

    const prevEllipsis = [
      (!allPagesDisplayed && currentPageIsNearEnd) || // display if hidden pages and curPage is near end
      (!allPagesDisplayed && !currentPageIsNearBegining && !currentPageIsNearEnd && currentPage !== 2) || // display if hidden pages and curPage is in middle and not page 2
      (currentPage === 2 && hideMaxMinPageNumbers) // display if curPage is 2 and first page is hidden
        ? getNewPage('prevEllipsis')
        : null,
    ];

    const nextEllipsis = [
      (!allPagesDisplayed && currentPageIsNearBegining) || // display if hidden pages and curPage is near start
      (!allPagesDisplayed &&
        !currentPageIsNearBegining &&
        !currentPageIsNearEnd &&
        currentPage !== totalNumberOfPages - 1) || // display if hidden pages and curPage is in middle and not second last page
      (currentPage === totalNumberOfPages - 1 && hideMaxMinPageNumbers) // display if curPage is second last page 2 and last page is hidden
        ? getNewPage('nextEllipsis')
        : null,
    ];

    const lastPage =
      (!hideMaxMinPageNumbers || currentPage === totalNumberOfPages || currentPageIsNearEnd) && totalNumberOfPages !== 1
        ? getNewPage('page', totalNumberOfPages)
        : null;

    return [firstPage, ...prevEllipsis, ...pages, ...nextEllipsis, lastPage].filter(
      (page) => page !== null && (page.pageNumber !== null || page.pageNumber !== undefined),
    );
  }, [currentPage, rawNumberOfPagesToShow, totalNumberOfPages, lowerPageBound, hideMaxMinPageNumbers]);
};

export default usePagination;
