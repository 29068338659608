import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';
import styled from 'styled-components';
import { themes, ThemeProps } from '../../shared';

type CardBodyProps = {
  children: React.ReactNode;
  hasHeader?: boolean;
  active?: boolean; // Passed to Highlight
  borderOnly?: boolean; // Body and passed to Highlight
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledCardBody = styled(({ hasHeader, theme, variation, borderOnly, ...rest }) => (
  <BootstrapCard.Body {...rest} />
))`
  ${({ theme, variation, hasHeader, borderOnly }) => {
    return `
      color: ${hasHeader || borderOnly ? theme.standard.basic.font : theme.standard[variation].font};
    `;
  }}
`;

const CardBody = ({
  theme,
  variation,
  children,
  hasHeader,
  active,
  borderOnly,
  ...props
}: CardBodyProps): JSX.Element => {
  const renderChildren = () => {
    const themeProps = {
      theme,
      variation,
    };
    return React.Children.map(children, (child: JSX.Element) => {
      if (
        child &&
        typeof child !== 'string' &&
        child.type.displayName &&
        child.type.displayName !== 'WithTheme(Loader)'
      ) {
        if (child.type.displayName === 'CardHighlight') {
          return React.cloneElement(child, {
            active,
            borderOnly,
            ...themeProps,
          });
        }
        return React.cloneElement(child, { ...themeProps });
      }
      return child;
    });
  };
  return (
    <StyledCardBody theme={theme} variation={variation} hasHeader={hasHeader} borderOnly={borderOnly} {...props}>
      {renderChildren()}
    </StyledCardBody>
  );
};
CardBody.displayName = 'CardBody';
CardBody.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  hasHeader: false,
  active: false,
  borderOnly: false,
};

export default CardBody;
