import React from 'react';
import type { PropsWithChildren } from 'react';
import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';
import type { DropAnimation } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

export const SortableOverlay = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <DragOverlay modifiers={[restrictToParentElement]} dropAnimation={dropAnimationConfig}>
      {children}
    </DragOverlay>
  );
};
