import React from 'react';
import styled from 'styled-components';
import { ImageBlock, MediaBlock, ParagraphBlock, TableBlock, TextRow, CardBlock } from '.';

const StyledPlaceholder = styled.div`
  width: 100%;
`;

type PlaceholderProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const Placeholder = ({ children, ...props }: PlaceholderProps): JSX.Element => (
  <StyledPlaceholder {...props}>{children}</StyledPlaceholder>
);

Placeholder.Text = TextRow;
Placeholder.Paragraph = ParagraphBlock;
Placeholder.Image = ImageBlock;
Placeholder.Media = MediaBlock;
Placeholder.Table = TableBlock;
Placeholder.Card = CardBlock;

export default Placeholder;
