import React from 'react';
import { withTheme } from 'styled-components';
import { themes, ThemeProps } from '../../shared';
import MessageTemplate, { MessageTemplateProps } from './MessageTemplate';

const Message = (): JSX.Element => <div />;

type MessageVariationProps = {
  overrideVariation?: boolean;
  noBorder?: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties &
  ThemeProps &
  Pick<
    MessageTemplateProps,
    | 'title'
    | 'message'
    | 'secondaryMsg'
    | 'details'
    | 'width'
    | 'hideTitle'
    | 'titleAs'
    | 'small'
    | 'animateIn'
    | 'minHeight'
    | 'borderRadius'
  >;

/* eslint-disable no-unneeded-ternary */
// className defaults to 'undefined' without ternary
const InfoMessage = ({
  title,
  hideTitle,
  message,
  secondaryMsg,
  titleAs,
  details,
  width,
  minHeight,
  small,
  animateIn,
  borderRadius,
  className,
  theme,
  variation,
  overrideVariation,
  noBorder,
  ...props
}: MessageVariationProps): JSX.Element => {
  return (
    <div
      className={`${
        className ? className : ''
      } h-100 d-flex justify-content-center align-items-center alert-message-wrapper`}
      {...props}
    >
      <MessageTemplate
        title={title || 'Info'}
        hideTitle={hideTitle}
        message={message}
        secondaryMsg={secondaryMsg}
        details={details}
        titleAs={titleAs || 'h3'}
        animateIn={animateIn}
        width={width || '35rem'}
        minHeight={minHeight}
        theme={theme}
        variation={overrideVariation ? variation : 'info'}
        noBorder={noBorder}
        small={small}
        borderRadius={borderRadius}
      />
    </div>
  );
};
InfoMessage.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  overrideVariation: false,
  noBorder: false,
};

const WarningMessage = ({
  title,
  hideTitle,
  message,
  secondaryMsg,
  titleAs,
  details,
  width,
  minHeight,
  small,
  animateIn,
  borderRadius,
  theme,
  variation,
  className,
  overrideVariation,
  noBorder,
  ...props
}: MessageVariationProps): JSX.Element => {
  return (
    <div
      className={`${
        className ? className : ''
      } h-100 d-flex justify-content-center align-items-center alert-message-wrapper`}
      {...props}
    >
      <MessageTemplate
        title={title || 'Warning'}
        hideTitle={hideTitle}
        message={message}
        secondaryMsg={secondaryMsg}
        details={details}
        titleAs={titleAs || 'h3'}
        animateIn={animateIn}
        width={width || '35rem'}
        minHeight={minHeight}
        theme={theme}
        variation={overrideVariation ? variation : 'warning'}
        noBorder={noBorder}
        small={small}
        borderRadius={borderRadius}
      />
    </div>
  );
};
WarningMessage.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  overrideVariation: false,
  noBorder: false,
};

const SuccessMessage = ({
  title,
  hideTitle,
  message,
  secondaryMsg,
  titleAs,
  details,
  width,
  minHeight,
  small,
  animateIn,
  borderRadius,
  theme,
  variation,
  className,
  overrideVariation,
  noBorder,
  ...props
}: MessageVariationProps): JSX.Element => {
  return (
    <div
      className={`${
        className ? className : ''
      } h-100 d-flex justify-content-center align-items-center alert-message-wrapper`}
      {...props}
    >
      <MessageTemplate
        title={title || 'Success!'}
        hideTitle={hideTitle}
        message={message}
        secondaryMsg={secondaryMsg}
        details={details}
        titleAs={titleAs || 'h3'}
        animateIn={animateIn}
        width={width || '35rem'}
        minHeight={minHeight}
        theme={theme}
        variation={overrideVariation ? variation : 'success'}
        noBorder={noBorder}
        small={small}
        borderRadius={borderRadius}
      />
    </div>
  );
};
SuccessMessage.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  overrideVariation: false,
  noBorder: false,
};

const ErrorMessage = ({
  title,
  hideTitle,
  message,
  secondaryMsg,
  titleAs,
  details,
  width,
  minHeight,
  small,
  animateIn,
  borderRadius,
  theme,
  variation,
  className,
  overrideVariation,
  noBorder,
  ...props
}: MessageVariationProps): JSX.Element => {
  return (
    <div
      className={`${
        className ? className : ''
      } h-100 d-flex justify-content-center align-items-center alert-message-wrapper`}
      {...props}
    >
      <MessageTemplate
        title={title || 'Error!'}
        hideTitle={hideTitle}
        message={message}
        secondaryMsg={secondaryMsg}
        details={details}
        titleAs={titleAs || 'h3'}
        animateIn={animateIn}
        width={width || '35rem'}
        minHeight={minHeight}
        theme={theme}
        variation={overrideVariation ? variation : 'danger'}
        noBorder={noBorder}
        small={small}
        borderRadius={borderRadius}
      />
    </div>
  );
};
ErrorMessage.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  overrideVariation: false,
  noBorder: false,
};

Message.Info = withTheme(InfoMessage);
Message.Warning = withTheme(WarningMessage);
Message.Error = withTheme(ErrorMessage);
Message.Success = withTheme(SuccessMessage);

export default withTheme(Message) as React.ForwardRefExoticComponent<{
  theme?: ThemeProps;
}> & {
  Info: typeof InfoMessage;
  Warning: typeof WarningMessage;
  Error: typeof ErrorMessage;
  Success: typeof SuccessMessage;
};
