import React, { useContext } from 'react';
import styled, { ThemeProvider, keyframes, css } from 'styled-components';
import { Accordion, Button, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import Card from '../Cards/Card';
import { themes, ThemeProps, constants } from '../../shared';
import mono from '../../styles/colours/_monochrome.scss';

// ! TODO: More details when small === true

export type MessageTemplateProps = {
  title?: string;
  hideTitle?: boolean;
  message?: string | null;
  secondaryMsg?: string | null;
  titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  animateIn?: boolean;
  details?: string | null;
  width?: string | '35rem' | '100%' | 'auto';
  minHeight?: string | '125px' | '150px' | 'auto';
  className?: string;
  small?: boolean;
  borderRadius?: keyof typeof constants.sizes;
  noBorder?: boolean;
} & ThemeProps;

const StyledIcon = styled(({ theme, variation, small, ...rest }) => <i {...rest} aria-label={`${variation} Icon`} />)`
  color: ${({ theme, variation }) => theme.standard[variation].font};
  opacity: 0.5;
  position: absolute;
  /* top: ${({ small }) => (small ? '-5px' : '-25px')};
  right: ${({ small }) => (small ? '-10px' : '-25px')};
  font-size: ${({ small }) => (small ? '3.5rem' : '10rem')}; */
  ${({ small, variation }) => {
    if (small) {
      return `
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: ${variation !== 'danger' ? '0.035' : '0.1'};
      `;
    }
    return `
      top: -25px;
      right: -25px;
    `;
  }}
  /* font-size: ${({ small }) => (small ? '3.5rem' : '10rem')}; */
  font-size: 10rem;

  @media (max-width: 375px) {
    font-size: 8rem;
    top: -20px;
  }
`;

const StyledText = styled(({ theme, variation, ...rest }) => <span {...rest} />)`
  color: ${({ theme, variation }) => theme.standard[variation].font};
`;

const StyledButton = styled(({ theme, variation, ...rest }) => <Button {...rest} />)`
  margin: 1rem 1.5rem;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid ${({ theme, variation }) => theme.muted[variation].colour};
  }
`;

const StyledSmall = styled(({ theme, variation, ...rest }) => <small {...rest} />)`
  color: ${({ theme, variation }) => theme.standard[variation].font};

  .more-details {
    &__chevron {
      transition: transform 150ms ease-out;
    }

    &__chevron--open {
      transform: rotate(-180deg);
    }

    &__chevron--close {
      transform: rotate(0deg);
    }

    &__text {
      position: relative;
    }

    &__text::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      visibility: hidden;
      background: ${({ theme, variation }) => theme.standard[variation].font};
      transform: scaleX(0);
      transition: all 0.25s cubic-bezier(1, 0.25, 0, 0.75) 0s;
    }

    &__text:hover::after {
      visibility: visible;
      transform: scaleX(1);
    }
  }
`;

const StyledCardText = styled(({ theme, ...rest }) => <Card.Text {...rest} />)`
  color: ${({ theme }) => (theme.name !== 'dark' ? mono.black : mono.white)};
  background-color: ${({ theme }) => theme.standard.basic.colour};
`;

const StyledTitle = styled.div``;
const StyledAccordion = styled(({ theme, ...rest }) => <Accordion {...rest} />)``;

const zoomIn = keyframes`
  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
`;

const StyledCard = styled(({ small, animateIn, minHeight, ...rest }) => <Card {...rest} />)`
  text-align: left;
  max-height: ${({ small }) => (small ? '125px' : 'none')};
  overflow: hidden;

  ${({ minHeight, small }) => {
    if (minHeight !== 'auto') {
      return `
        min-height: ${small ? '125px' : '150px'};
      `;
    }
    return `
      min-height: auto;
    `;
  }}
  ${({ animateIn }) =>
    animateIn
      ? css`
          animation: ${zoomIn} 400ms ease-out;
        `
      : null};

  .alert-message {
    &__card__body {
      overflow-y: ${({ small }) => (small ? 'scroll' : 'hidden')};

      &::-webkit-scrollbar {
        width: 10px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
      }

      &__text-content {
        max-width: ${({ small }) => (small ? '100%' : '75%')};
        position: relative;
      }
    }
  }
`;

type ToggleProps = {
  eventKey: string;
  // eslint-disable-next-line react/require-default-props
  callback?: (eventKey: string) => void;
} & ThemeProps;

const ContextAwareToggle = ({ eventKey, callback, variation, theme }: ToggleProps) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <StyledButton theme={theme} variation={variation} variant="link" onClick={decoratedOnClick}>
      <StyledSmall variation={variation}>
        <span
          className={`${
            isCurrentEventKey ? 'more-details__chevron--open' : 'more-details__chevron--close'
          } more-details__chevron d-inline-block mr-1`}
        >
          <i className="fas fa-chevron-down" aria-label="More Details Chevron Icon" />
        </span>
        <span className="more-details__text">More Details</span>
      </StyledSmall>
    </StyledButton>
  );
};

const MessageTemplate = ({
  theme,
  variation,
  title,
  hideTitle,
  message,
  titleAs,
  secondaryMsg,
  details,
  animateIn,
  className,
  width,
  minHeight,
  small,
  borderRadius,
  noBorder,
  ...props
}: MessageTemplateProps): JSX.Element => {
  const getIcon = (): string => {
    switch (variation) {
      case 'info':
        return 'fas fa-info-circle fa-5x';
      case 'warning':
        return 'fas fa-exclamation-circle fa-5x';
      case 'success':
        return 'fas fa-check-circle fa-5x';
      case 'danger':
        return 'fas fa-times-circle fa-5x';
    }
    return '';
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledCard
        width={width}
        minHeight={minHeight}
        small={small}
        className={`${small ? 'alert-message-sm' : 'alert-message'} ${className}`}
        variation={variation}
        animateIn={animateIn}
        borderRadius={borderRadius}
        showBorder={!noBorder}
        {...props}
      >
        <Card.Body
          // className={`${!small ? 'd-flex justify-content-center flex-column' : ''} p-0 alert-message__card__body`}
          className="d-flex justify-content-center flex-column p-0 alert-message__card__body"
        >
          <StyledIcon variation={variation} small={small} className={getIcon()} />
          <div className={`${small ? 'p-3' : 'p-4'} alert-message__card__body__text-content`}>
            <div>
              {!hideTitle ? (
                <>
                  {small ? (
                    <h4 className="mb-2 title">
                      <StyledText variation={variation}>{title}</StyledText>
                    </h4>
                  ) : (
                    <StyledTitle as={titleAs} className="mb-3 title">
                      <StyledText variation={variation}>{title}</StyledText>
                    </StyledTitle>
                  )}
                </>
              ) : null}
              {message ? (
                <>
                  {small ? (
                    <h6 className={`${secondaryMsg ? '' : 'mb-0'} message`}>
                      <StyledText style={{ fontSize: '15px' }} variation={variation}>
                        {message}
                      </StyledText>
                    </h6>
                  ) : (
                    <h5 className={`${secondaryMsg ? '' : 'mb-0'} message`}>
                      <StyledText variation={variation}>{message}</StyledText>
                    </h5>
                  )}
                </>
              ) : null}
              {secondaryMsg ? (
                <>
                  {small ? (
                    <h6 className="mb-0 secondary-msg">
                      <StyledText style={{ fontSize: '13px' }} variation={variation}>
                        {secondaryMsg}
                      </StyledText>
                    </h6>
                  ) : (
                    <h6 className={`${details ? '' : 'mb-0'} secondary-msg`}>
                      <StyledText variation={variation}>{secondaryMsg}</StyledText>
                    </h6>
                  )}
                </>
              ) : null}
            </div>
          </div>

          {details ? (
            <>
              {small ? null : (
                <StyledAccordion>
                  <ContextAwareToggle variation={variation} theme={theme} eventKey="0" />
                  <Accordion.Collapse eventKey="0">
                    <StyledCardText className="py-3 pr-3 pl-4 details">{details}</StyledCardText>
                  </Accordion.Collapse>
                </StyledAccordion>
              )}
            </>
          ) : null}
        </Card.Body>
      </StyledCard>
    </ThemeProvider>
  );
};

MessageTemplate.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  animateIn: true,
  hideTitle: false,
  title: '',
  message: '',
  secondaryMsg: '',
  details: '',
  className: '',
  titleAs: 'h1',
  width: '35rem',
  minHeight: 'auto',
  small: false,
  borderRadius: 'md',
  noBorder: false,
};

export default MessageTemplate;
