import React, { useEffect, forwardRef, useState } from 'react';
import styled from 'styled-components';
import { OverlayTrigger, OverlayTriggerProps, Tooltip, TooltipProps } from 'react-bootstrap';
import { OverlayDelay, OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger';
import useCopyClipboard from '../utils/useCopyClipboard';

type HoverTextProps = Pick<OverlayTriggerProps, 'placement'> & {
  children: React.ReactElement | ((props: OverlayTriggerRenderProps) => React.ReactNode);
  tooltip: React.ReactNode | null;
  tooltipCopy?: boolean;
  id: string;
  delay?: OverlayDelay;
};

const HoverText: React.FC<HoverTextProps> = ({
  children,
  id,
  tooltip,
  tooltipCopy = false,
  delay = { show: 100, hide: 1 },
  ...props
}: HoverTextProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const clonedChildren = children
    ? React.cloneElement(children as React.ReactElement, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })
    : children;

  return (
    <>
      {tooltip ? (
        <OverlayTrigger
          show={tooltipCopy ? showTooltip : undefined}
          delay={delay}
          overlay={
            <CustomTooltip
              tooltipCopy={tooltipCopy}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              setClose={setShowTooltip}
              id={`${id}-tooltip`}
            >
              {tooltip}
            </CustomTooltip>
          }
          {...props}
          transition
        >
          {clonedChildren}
        </OverlayTrigger>
      ) : (
        <>{clonedChildren}</>
      )}
    </>
  );
};

const CustomTooltip = forwardRef(
  (
    {
      id,
      children,
      popper,
      tooltipCopy,
      setClose,
      ...props
    }: TooltipProps & { tooltipCopy: boolean | undefined; setClose: React.Dispatch<React.SetStateAction<boolean>> },
    ref,
  ): JSX.Element => {
    const [isCopied, handleCopy, copyPromise] = useCopyClipboard(2000);

    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper, isCopied]);

    return (
      <StyledTooltip
        $copiedToClipboard={isCopied}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseOut}
        ref={ref}
        id={id}
        {...props}
        onClick={(e: { stopPropagation: () => void }) => {
          e.stopPropagation();
        }}
      >
        {tooltipCopy ? (
          <>
            {!isCopied ? (
              <div className="d-flex flex-row">
                <div className="flex-column flex-wrap">{children}</div>
                <div className="flex-column">
                  <CopyButton
                    onClick={(e) => {
                      const node = document.getElementById(id);
                      const nodeText = node && node.textContent ? node.textContent.trim() : '';
                      handleCopy(nodeText);
                      copyPromise.then(() => setClose(false));
                      e.stopPropagation();
                    }}
                  >
                    <i className="far fa-clone" />
                  </CopyButton>
                </div>
              </div>
            ) : (
              <div>Copied!</div>
            )}
          </>
        ) : (
          children
        )}
      </StyledTooltip>
    );
  },
);
CustomTooltip.displayName = 'CustomTooltip';

const CopyButton = styled.button`
  display: inline;
  border: 0;
  outline: none;
  background-color: transparent;
  color: #fff;
  border-radius: 32px;

  i {
    transform: rotate(90deg);
    height: 10px;
    font-size: 12px;
  }

  :focus {
    outline: transparent;
    background-color: rgba(255, 255, 255, 0.1);
  }

  :hover,
  :focus-visible {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const StyledTooltip = styled(Tooltip)<{ $copiedToClipboard: boolean }>`
  .tooltip-inner {
    max-width: 320px;
    background-color: ${({ $copiedToClipboard }) => ($copiedToClipboard ? '#6fcf97' : undefined)};
  }

  .arrow::before {
    border-top-color: ${({ $copiedToClipboard }) => ($copiedToClipboard ? '#6fcf97' : undefined)};
  }
`;

HoverText.defaultProps = {
  tooltipCopy: false,
  delay: { show: 100, hide: 1 },
};

export default HoverText;
