import React from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { themes, ThemeProps } from '../shared';

type DividerType = {
  visible?: boolean;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const Divider = ({ visible, theme, ...props }: DividerType): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <Line {...props} visible={visible} />
    </ThemeProvider>
  );
};

const Line = styled.div<{ visible?: boolean }>`
  background-color: ${({ theme }) => theme?.muted.light.accent};
  display: ${({ visible }) => (visible === true || visible === undefined ? 'block' : 'none')};
  height: 1px;
  width: 100%;
`;

Divider.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  visible: true,
};

export default withTheme(Divider);
