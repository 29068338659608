import indigo from './indigoPallet';
import mono from '../../styles/colours/_monochrome.scss';
import { ThemedSectionType } from './types';

const { primary, secondary, info, success, warning, danger } = indigo;

const indigoPallet: ThemedSectionType = {
  name: 'indigo',
  standard: {
    basic: {
      colour: mono.white,
      font: mono.black,
      accent: primary.gigas.background,
    },
    primary: {
      colour: primary.gigas.background,
      font: primary.gigas.foreground,
      accent: success.atlantis.background,
    },
    secondary: {
      colour: secondary.woodsmoke.background,
      font: secondary.woodsmoke.foreground,
      accent: '',
    },
    success: {
      colour: success.bilbao.background,
      font: success.bilbao.foreground,
      accent: '',
    },
    info: {
      colour: info['cerulean-blue'].background,
      font: info['cerulean-blue'].foreground,
      accent: '',
    },
    warning: {
      colour: warning.corn.background,
      font: warning.corn.foreground,
      accent: '',
    },
    danger: {
      colour: danger['rose-of-sharon'].background,
      font: danger['rose-of-sharon'].foreground,
      accent: '',
    },
    light: {
      colour: mono.light,
      font: mono.black,
      accent: mono.medium,
    },
    dark: {
      colour: mono.black,
      font: mono.white,
      accent: mono.black,
    },
  },
  muted: {
    basic: {
      colour: mono.white,
      font: mono.black,
      accent: '',
    },
    primary: {
      colour: primary.snuff.background,
      font: primary.snuff.foreground,
      accent: '',
    },
    secondary: {
      colour: secondary.aluminium.background,
      font: secondary.aluminium.foreground,
      accent: '',
    },
    success: {
      colour: success['tea-green'].background,
      font: success['tea-green'].foreground,
      accent: '',
    },
    info: {
      colour: info['white-lilac'].background,
      font: info['white-lilac'].foreground,
      accent: '',
    },
    warning: {
      colour: warning['orange-white'].background,
      font: warning['orange-white'].foreground,
      accent: '',
    },
    danger: {
      colour: danger['macaroni-and-cheese'].background,
      font: danger['macaroni-and-cheese'].foreground,
      accent: '',
    },
    light: {
      colour: mono.light,
      font: mono.black,
      accent: mono.medium,
    },
    dark: {
      colour: secondary.raven.background,
      font: secondary.raven.foreground,
      accent: mono.black,
    },
  },
  inverse: {
    basic: {
      colour: mono.white,
      font: mono.black,
      accent: primary.gigas.background,
    },
    primary: {
      colour: primary.gigas.foreground,
      font: primary.gigas.background,
      accent: '',
    },
    secondary: {
      colour: secondary.woodsmoke.foreground,
      font: secondary.woodsmoke.background,
      accent: '',
    },
    success: {
      colour: success.bilbao.foreground,
      font: success.bilbao.background,
      accent: '',
    },
    danger: {
      colour: danger['rich-gold'].foreground,
      font: danger['rich-gold'].background,
      accent: '',
    },
    warning: {
      colour: mono.white,
      font: mono.black,
      accent: warning.corn.background,
    },
    info: {
      colour: info['cerulean-blue'].foreground,
      font: info['cerulean-blue'].background,
      accent: '',
    },
    light: {
      colour: mono.white,
      font: mono.dark,
      accent: mono.dark,
    },
    dark: {
      colour: mono.white,
      font: mono.black,
      accent: mono.black,
    },
  },
};

export default indigoPallet;
