const typography = {
  type: {
    headings: '"Quicksand", "sans-serif"',
    body: '"Open Sans", "Verdana", "sans-serif"',
  },
  weight: {
    light: '300',
    regular: '400',
    semiBold: '500',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  size: {
    s1: '12',
    s2: '14',
    s3: '16',
    m1: '20',
    m2: '24',
    m3: '28',
    l1: '32',
    l2: '40',
    l3: '48',
    code: '90',
  },
};

export default typography;
