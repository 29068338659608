import React from 'react';
import styled from 'styled-components';
import { themes, ThemeProps } from '../../shared';
import mpac from '../../styles/colours/_mpac.scss';

type CardHighlightProps = {
  children: React.ReactNode;
  active?: boolean;
  borderOnly?: boolean;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledCardHighlight = styled.div<Pick<CardHighlightProps, 'theme' | 'variation' | 'active' | 'borderOnly'>>`
  ${({ theme, variation, active, borderOnly }) => {
    const parseColour = () => {
      if (variation === 'basic' || borderOnly) {
        return theme.standard.basic.accent;
      }
      return theme.standard[variation].accent;
    };
    return `
      color: ${active ? mpac['screamin-green'] : parseColour()};
    `;
  }}
`;

const CardHighlight = ({
  theme,
  variation,
  children,
  active,
  borderOnly,
  className,
  ...props
}: CardHighlightProps): JSX.Element => {
  return (
    <StyledCardHighlight
      theme={theme}
      variation={variation}
      active={active}
      borderOnly={borderOnly}
      className={`${className ? className : ''} card-highlight`} // eslint-disable-line no-unneeded-ternary
      {...props}
    >
      {children}
    </StyledCardHighlight>
  );
};
CardHighlight.displayName = 'CardHighlight';
CardHighlight.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
  active: false,
  borderOnly: false,
};

export default CardHighlight;
