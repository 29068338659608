// From https://gomakethings.com/how-to-check-if-any-part-of-an-element-is-out-of-the-viewport-with-vanilla-js/

class CheckViewport {
  bounding: DOMRect;
  out: {
    top: boolean;
    left: boolean;
    bottom: boolean;
    right: boolean;
    any: boolean;
    all: boolean;
  };

  constructor(elem: HTMLElement) {
    this.bounding = elem.getBoundingClientRect();
    this.out = { top: false, left: false, bottom: false, right: false, any: false, all: false };
    this.out.top = this.bounding.top < 0;
    this.out.left = this.bounding.left < 0;
    this.out.bottom = this.bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    this.out.right = this.bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    this.out.any = this.out.top || this.out.left || this.out.bottom || this.out.right;
    this.out.all = this.out.top && this.out.left && this.out.bottom && this.out.right;
  }

  isOutOfViewport(): typeof this.out {
    return this.out;
  }
}

export default CheckViewport;
