import React from 'react';
import styled from 'styled-components';
import TextRow from './TextRow';

type StyledParagraphBlockProps = {
  rows: number;
  singleLineWidth: number;
  lineWidths: number[];
  lineHeight?: string;
};
const StyledParagraphBlock = styled(TextRow)`
  max-height: ${({ rows }: StyledParagraphBlockProps) => 100 / (rows * 2 - 1)}%;
  width: ${({ lineWidths, singleLineWidth }) => lineWidths[(singleLineWidth + lineWidths.length) % lineWidths.length]}%;
`;

const StyledParagraphBlockWrapper = styled.div`
  width: ${({ width }: { width: string }) => width};
`;

type ParagraphBlockProps = {
  rows?: number;
  width?: string;
  lineWidths?: number[];
  lineHeight?: string;
} & React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const ParagraphBlock = ({ rows, width, lineHeight, lineWidths, ...props }: ParagraphBlockProps): JSX.Element => {
  return (
    <StyledParagraphBlockWrapper width={width} {...props}>
      {Array(...Array(rows)).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledParagraphBlock key={i} rows={rows} singleLineWidth={i} lineHeight={lineHeight} lineWidths={lineWidths} />
      ))}
    </StyledParagraphBlockWrapper>
  );
};

ParagraphBlock.defaultProps = {
  rows: 4,
  width: '100%',
  lineHeight: '1em',
  lineWidths: [97, 100, 94, 90, 98, 95, 98, 40],
};

export default ParagraphBlock;
