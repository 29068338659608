import indigo from '../../styles/colours/_indigo.scss';

const primaryPallet = {
  gigas: {
    background: indigo.gigas,
    foreground: '#fff',
  },
  'royal-purple': {
    background: indigo['royal-purple'],
    foreground: '#fff',
  },
  'blue-violet': {
    background: indigo['blue-violet'],
    foreground: '#fff',
  },
  'blue-marguerite': {
    background: indigo['blue-marguerite'],
    foreground: '#fff',
  },
  'moody-blue': {
    background: indigo['moody-blue'],
    foreground: '#000',
  },
  'chetwode-indigo': {
    background: indigo['chetwode-indigo'],
    foreground: '#000',
  },
  'cold-purple': {
    background: indigo['cold-purple'],
    foreground: '#000',
  },
  prelude: {
    background: indigo.prelude,
    foreground: '#000',
  },
  'prelude-light': {
    background: indigo['prelude-light'],
    foreground: '#000',
  },
  snuff: {
    background: indigo.snuff,
    foreground: '#000',
  },
};

const dangerPallet = {
  'rich-gold': {
    background: indigo['rich-gold'],
    foreground: '#fff',
  },
  'rose-of-sharon': {
    background: indigo['rose-of-sharon'],
    foreground: '#fff',
  },
  bamboo: {
    background: indigo.bamboo,
    foreground: '#000',
  },
  christine: {
    background: indigo.christine,
    foreground: '#000',
  },
  ecstasy: {
    background: indigo.ecstasy,
    foreground: '#000',
  },
  crusta: {
    background: indigo.crusta,
    foreground: '#000',
  },
  'tan-hide': {
    background: indigo['tan-hide'],
    foreground: '#000',
  },
  'tan-hide-light': {
    background: indigo['tan-hide-light'],
    foreground: '#000',
  },
  rajah: {
    background: indigo.rajah,
    foreground: '#000',
  },
  'macaroni-and-cheese': {
    background: indigo['macaroni-and-cheese'],
    foreground: '#000',
  },
};

const warningPallet = {
  corn: {
    background: indigo.corn,
    foreground: '#000',
  },
  saffron: {
    background: indigo.saffron,
    foreground: '#000',
  },
  'cream-can': {
    background: indigo['cream-can'],
    foreground: '#000',
  },
  'energy-yellow': {
    background: indigo['energy-yellow'],
    foreground: '#000',
  },
  'marigold-yellow': {
    background: indigo['marigold-yellow'],
    foreground: '#000',
  },
  'sweet-corn': {
    background: indigo['sweet-corn'],
    foreground: '#000',
  },
  'banana-mania': {
    background: indigo['banana-mania'],
    foreground: '#000',
  },
  'double-pearl-lusta': {
    background: indigo['double-pearl-lusta'],
    foreground: '#000',
  },
  'off-yellow': {
    background: indigo['off-yellow'],
    foreground: '#000',
  },
  'orange-white': {
    background: indigo['orange-white'],
    foreground: '#000',
  },
};

const infoPallet = {
  'cerulean-blue': {
    background: indigo['cerulean-blue'],
    foreground: '#fff',
  },
  indigo: {
    background: indigo.indigo,
    foreground: '#fff',
  },
  danube: {
    background: indigo.danube,
    foreground: '#000',
  },
  'chetwode-blue': {
    background: indigo['chetwode-blue'],
    foreground: '#000',
  },
  'dull-lavender': {
    background: indigo['dull-lavender'],
    foreground: '#000',
  },
  spindle: {
    background: indigo.spindle,
    foreground: '#000',
  },
  'spindle-light': {
    background: indigo['spindle-light'],
    foreground: '#000',
  },
  'link-water': {
    background: indigo['link-water'],
    foreground: '#000',
  },
  'link-water-light': {
    background: indigo['link-water-light'],
    foreground: '#000',
  },
  'white-lilac': {
    background: indigo['white-lilac'],
    foreground: '#000',
  },
};

const successPallet = {
  bilbao: {
    background: indigo.bilbao,
    foreground: '#fff',
  },
  'la-palma': {
    background: indigo['la-palma'],
    foreground: '#fff',
  },
  'vida-loca': {
    background: indigo['vida-loca'],
    foreground: '#000',
  },
  lima: {
    background: indigo.lima,
    foreground: '#000',
  },
  atlantis: {
    background: indigo.atlantis,
    foreground: '#000',
  },
  conifer: {
    background: indigo.conifer,
    foreground: '#000',
  },
  'conifer-light': {
    background: indigo['conifer-light'],
    foreground: '#000',
  },
  'yellow-green': {
    background: indigo['yellow-green'],
    foreground: '#000',
  },
  caper: {
    background: indigo.caper,
    foreground: '#000',
  },
  'tea-green': {
    background: indigo['tea-green'],
    foreground: '#000',
  },
};

const secondaryPallet = {
  woodsmoke: {
    background: indigo.woodsmoke,
    foreground: '#fff',
  },
  shark: {
    background: indigo.shark,
    foreground: '#fff',
  },
  tuna: {
    background: indigo.tuna,
    foreground: '#fff',
  },
  abbey: {
    background: indigo.abbey,
    foreground: '#fff',
  },
  'shuttle-grey': {
    background: indigo['shuttle-grey'],
    foreground: '#fff',
  },
  'pale-sky': {
    background: indigo['pale-sky'],
    foreground: '#fff',
  },
  raven: {
    background: indigo.raven,
    foreground: '#000',
  },
  manatee: {
    background: indigo.manatee,
    foreground: '#000',
  },
  'gray-chateau': {
    background: indigo['gray-chateau'],
    foreground: '#000',
  },
  aluminium: {
    background: indigo.aluminium,
    foreground: '#000',
  },
};

const indigoPallet = {
  primary: primaryPallet,
  secondary: secondaryPallet,
  success: successPallet,
  danger: dangerPallet,
  warning: warningPallet,
  info: infoPallet,
};

export default indigoPallet;
