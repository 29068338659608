import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import nFormatter from '../utils/nFormatter';
import { themes, ThemeProps } from '../shared';

type CountUpType = {
  asSVGText?: boolean;
  startVal?: number;
  endVal: number;
  decimals?: number;
  duration?: number;
  useEasing?: boolean;
  nFormat?: boolean | number;
  separate?: boolean;
  prefix?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  suffix?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  signBasedColour?: boolean;
  x?: number;
  y?: number;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledCountUpSpan = styled.span<Pick<CountUpType, 'theme' | 'signBasedColour'> & { count: number }>`
  ${({ theme, signBasedColour, count }) => {
    const parseSignBasedColour = () => {
      if (signBasedColour && count !== 0) {
        if (count > 0) {
          return theme.standard.success.colour;
        }
        return theme.standard.danger.colour;
      }
    };
    return `
      color: ${parseSignBasedColour()};
    `;
  }}
`;

const CountUp = ({
  theme,
  startVal,
  asSVGText,
  endVal,
  duration,
  decimals,
  useEasing,
  nFormat,
  separate,
  prefix,
  suffix,
  signBasedColour,
  style,
  className,
  x,
  y,
  ...props
}: CountUpType): JSX.Element => {
  const [countUp, setCountUp] = useState(null);
  const [count, setCount] = useState(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const rAF = useRef<number | null>(null);

  const easeOutExpo = (TElapsed: number, TStart: number, TDelta: number, TDuration: number): number => {
    return (TDelta * (-Math.pow(2, (-10 * TElapsed) / TDuration) + 1) * 1024) / 1023 + TStart;
  };

  useEffect(() => {
    setCount(startVal);
  }, [startVal]);

  useEffect(() => {
    if (endVal > startVal) {
      setCountUp(true);
    } else {
      setCountUp(false);
    }
  }, [startVal, endVal]);

  const animate = useCallback(
    (timestamp: number) => {
      if (!startTime) {
        setStartTime(() => timestamp);
      }

      const elapsedTime = timestamp - startTime;
      // const remainingTime = duration - elapsedTime;
      if (elapsedTime < duration) {
        let newCount = 0;
        if (countUp) {
          if (useEasing) {
            newCount = easeOutExpo(elapsedTime, startVal, endVal - startVal, duration);
          } else {
            newCount = startVal + (endVal - startVal) * (elapsedTime / duration);
          }
        }
        if (!countUp) {
          if (useEasing) {
            newCount = startVal - easeOutExpo(elapsedTime, 0, startVal - endVal, duration);
          } else {
            newCount = startVal - (startVal - endVal) * (elapsedTime / duration);
          }
        }
        newCount = Number(newCount.toFixed(decimals));
        setCount(() => {
          return newCount;
        });
      }
      if (elapsedTime > duration) {
        if (startTime !== null) {
          setCount(() => {
            return endVal;
          });
        }
      }
      rAF.current = requestAnimationFrame(animate);
    },
    [startTime, countUp, startVal, endVal, duration, decimals, useEasing],
  );

  useEffect(() => {
    rAF.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(rAF.current ? rAF.current : 0);
    };
  }, [animate]);

  useEffect(() => {
    return () => {
      setCountUp(null);
      setCount(null);
      setStartTime(null);
      rAF.current = null;
    };
  }, []);

  useEffect(() => {
    if (countUp && count >= endVal) {
      cancelAnimationFrame(rAF.current ? rAF.current : 0);
    }
    if (!countUp && count <= endVal) {
      cancelAnimationFrame(rAF.current ? rAF.current : 0);
    }
  }, [count, endVal, countUp]);

  const renderValue = () => {
    if (nFormat) {
      if (typeof nFormat === 'number') {
        return nFormatter(count, nFormat);
      }
      return nFormatter(count, decimals);
    }
    if (count) {
      if (separate) {
        return count ? count.toLocaleString() : count;
      }
      if (decimals) {
        return count.toFixed(decimals);
      }
    }
    return count;
  };

  return (
    <>
      {asSVGText ? (
        <text style={style} className={className} x={x} y={y}>
          {prefix}
          {renderValue()}
          {suffix}
        </text>
      ) : (
        <StyledCountUpSpan {...props} signBasedColour={signBasedColour} count={count} theme={theme}>
          {prefix}
          {renderValue()}
          {suffix}
        </StyledCountUpSpan>
      )}
    </>
  );
};

CountUp.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  duration: 2000,
  useEasing: true,
  nFormat: false,
  separate: false,
  signBasedColour: false,
  asSVGText: false,
  prefix: '',
  suffix: '',
  decimals: 0,
  startVal: 0,
};

export default CountUp;
