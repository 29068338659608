import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import TextRow, { TextRowType } from './TextRow';

const StyledTableCell = styled.td`
  border-top: ${({ noCellBorders }: Pick<TableBlockType, 'noCellBorders'>) =>
    noCellBorders ? 'none !important' : '#dee2e6'};
`;

const StyledTableTextRow = styled(TextRow)`
  margin: ${(props) => props.rowGap} 0;
`;

type TableBlockType = {
  headers?: Array<string>;
  type?: 'table' | 'body' | 'row';
  rows?: number;
  rowGap?: string;
  columns?: number;
  noCellBorders?: boolean;
} & React.TableHTMLAttributes<HTMLTableElement> &
  React.HtmlHTMLAttributes<HTMLTableSectionElement> &
  React.CSSProperties &
  Omit<TextRowType, 'length'>;

const TableBlock = ({
  rows,
  rowGap,
  columns,
  headers,
  type,
  lineHeight,
  noCellBorders,
  ...props
}: TableBlockType): JSX.Element => {
  const parseHeaders = () => {
    if (headers) {
      if (Array.isArray(headers)) {
        return (
          <thead>
            <tr>
              {headers.map((cell) => (
                <th key={cell}>{cell}</th>
              ))}
            </tr>
          </thead>
        );
      }
      return (
        <thead>
          <tr>
            {Array(...Array(columns)).map((_, x) => (
              // eslint-disable-next-line react/no-array-index-key
              <th key={x}>
                <StyledTableTextRow lineHeight={lineHeight} rowGap={rowGap} />
              </th>
            ))}
          </tr>
        </thead>
      );
    }
    return null;
  };

  const renderTableRow = () => (
    <>
      {Array(...Array(rows)).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={i}>
          {/* eslint-disable-next-line no-shadow */}
          {Array(...Array(columns)).map((k, x) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledTableCell key={`${i}-${x}`} noCellBorders={noCellBorders}>
              <StyledTableTextRow lineHeight={lineHeight} rowGap={rowGap} />
            </StyledTableCell>
          ))}
        </tr>
      ))}
    </>
  );

  const renderTableBody = () => {
    return (
      <>
        {parseHeaders()}
        <tbody {...props}>{renderTableRow()}</tbody>
      </>
    );
  };

  const renderTableBlock = (): JSX.Element => {
    switch (type) {
      case 'body':
        return renderTableBody();
      case 'row':
        return renderTableRow();
      default:
        return <Table {...props}>{renderTableBody()}</Table>;
    }
  };

  return renderTableBlock();
};

TableBlock.defaultProps = {
  headers: undefined,
  rows: 5,
  rowGap: '0',
  columns: 4,
  type: 'table',
  noCellBorders: false,
};

export default TableBlock;
