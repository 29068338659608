import React from 'react';
import styled from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';
import { themes, ThemeProps } from '../../shared';

type CardFooterProps = {
  children: React.ReactNode;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const StyledCardFooter = styled(({ theme, variation, ...rest }) => <BootstrapCard.Footer {...rest} />)`
  ${({ theme, variation }) => {
    return `
      ${variation !== 'basic' ? `background: ${theme.standard[variation].colour}` : ''};
      color: ${theme.standard[variation].font};
    `;
  }}
`;

const CardFooter = ({ theme, variation, children, ...props }: CardFooterProps): JSX.Element => (
  <StyledCardFooter theme={theme} variation={variation} {...props}>
    {children}
  </StyledCardFooter>
);
CardFooter.displayName = 'CardFooter';
CardFooter.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types
};

export default CardFooter;
