import styled from 'styled-components';

export const CardContainer = styled.div<{ cardSize?: string; font: string }>`
  font-family: ${({ font }) => font && font};
  min-height: ${({ cardSize }) => (cardSize === 'small' ? '130px' : '240px')};
  min-width: ${({ cardSize }) => (cardSize === 'small' ? '300px' : '440px')};
  max-width: auto;
  padding: ${({ cardSize }) => (cardSize === 'small' ? '20px 0 0 0px' : '20px')};
  box-shadow: rgb(0 0 0 / 7%) 3px 3px 10px 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  position: relative;
`;

export const StyledStatsIcon = styled.i<{
  $opacity?: boolean;
  $iconColor?: string;
  $hightlightStat?: string;
}>`
  z-index: 0;
  position: ${({ $hightlightStat }) => !$hightlightStat && 'absolute'};
  top: ${({ $hightlightStat }) => (!$hightlightStat ? '0' : '3px')};
  bottom: ${({ $hightlightStat }) => !$hightlightStat && '40px'};
  color: ${({ $iconColor }) => ($iconColor ? $iconColor : 'lightblue')};
  opacity: ${({ $opacity }) => ($opacity ? '0.35' : '0.8')};
`;

export const HighLightIcon = styled.i<{ rotateHighLight?: string }>`
  transform: ${({ rotateHighLight }) => rotateHighLight && rotateHighLight};
`;

export const StyledTypeText = styled.p<{ cardSize?: string }>`
  font-size: 22px;
  margin: ${({ cardSize }) => cardSize === 'small' && '0 0 0 25px'};
  position: relative;
  z-index: 1;
`;

export const StyledMainText = styled.p<{ cardSize?: string }>`
  font-size: 30px;
  margin: ${({ cardSize }) => cardSize === 'small' && '0 0 0 25px'};
  color: ${({ theme }) => theme?.standard.primary.colour};
  font-weight: 700;
  position: relative;
  z-index: 1;
`;

export const MultipleFooterText = styled.p<{ cardSize?: string }>`
  position: absolute;
  bottom: -12px;
  padding: ${({ cardSize }) => (cardSize === 'small' ? '5px 25px 5px 25px' : '5px 13px 0px 8px')};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const LongFooterText = styled.div<{ longFooterText?: string }>`
  font-size: 14px;
  color: #6c757d;
  font-style: italic;
  margin-top: 5px;
`;

export const HighLightTextContainer = styled.div<{ highlightcolor?: string }>`
  display: flex;
  justify-content: end;
  color: ${({ highlightcolor }) => (highlightcolor ? highlightcolor : ' rgb(45, 76, 94)')};
`;

export const HighlightStatText = styled.p`
  background-color: rgba(45, 76, 94, 0.09);
  border-radius: 10rem;
  font-weight: 700;
  line-height: 1;
  padding: 0.25em 0.6em 0.4em 0.6em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

export const YearText = styled.p`
  font-size: 0.875em;
  font-weight: 400;
  color: #6c757d;
`;

export const MiddleContainer = styled.div<{ hightlightStat?: string }>`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-top: ${({ hightlightStat }) => (hightlightStat ? '' : '20px')};
`;

export const IconTextContainer = styled.div`
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  line-height: 1;
  text-align: end;
`;
export const IconTextSubHeader = styled.p`
  font-size: 14px;
  color: #6c757d;
`;
