import dark from '../../styles/colours/_dark.scss';

const primaryPallet = {
  rhino: {
    background: dark.rhino,
    foreground: '#fff',
  },
  'easy-bay': {
    background: dark['easy-bay'],
    foreground: '#fff',
  },
  'kashmir-blue': {
    background: dark['kashmir-blue'],
    foreground: '#fff',
  },
  wedgewood: {
    background: dark.wedgewood,
    foreground: '#000',
  },
  'ship-cove': {
    background: dark['ship-cove'],
    foreground: '#000',
  },
  nepal: {
    background: dark.nepal,
    foreground: '#000',
  },
  'rock-blue': {
    background: dark['rock-blue'],
    foreground: '#000',
  },
  'pigeon-post': {
    background: dark['pigeon-post'],
    foreground: '#000',
  },
  botticelli: {
    background: dark.botticelli,
    foreground: '#000',
  },
  mystic: {
    background: dark.mystic,
    foreground: '#000',
  },
};

const dangerPallet = {
  'cafe-royale': {
    background: dark['cafe-royale'],
    foreground: '#fff',
  },
  korma: {
    background: dark.korma,
    foreground: '#fff',
  },
  'fiery-orange': {
    background: dark['fiery-orange'],
    foreground: '#000',
  },
  meteor: {
    background: dark.meteor,
    foreground: '#000',
  },
  tango: {
    background: dark.tango,
    foreground: '#000',
  },
  jaffa: {
    background: dark.jaffa,
    foreground: '#000',
  },
  'sandy-brown': {
    background: dark['sandy-brown'],
    foreground: '#000',
  },
  tacao: {
    background: dark.tacao,
    foreground: '#000',
  },
  manhattan: {
    background: dark.manhattan,
    foreground: '#000',
  },
  'manhattan-light': {
    background: dark['manhattan-light'],
    foreground: '#000',
  },
};

const warningPallet = {
  'yellow-metal': {
    background: dark['yellow-metal'],
    foreground: '#fff',
  },
  driftwood: {
    background: dark.driftwood,
    foreground: '#000',
  },
  husk: {
    background: dark.husk,
    foreground: '#000',
  },
  gimblet: {
    background: dark.gimblet,
    foreground: '#000',
  },
  'sorrell-brown': {
    background: dark['sorrell-brown'],
    foreground: '#000',
  },
  yuma: {
    background: dark.yuma,
    foreground: '#000',
  },
  akaroa: {
    background: dark.akaroa,
    foreground: '#000',
  },
  bone: {
    background: dark.bone,
    foreground: '#000',
  },
  'white-rock': {
    background: dark['white-rock'],
    foreground: '#000',
  },
  'white-rock-light': {
    background: dark['white-rock-light'],
    foreground: '#000',
  },
};

const infoPallet = {
  'rhino-purple': {
    background: dark['rhino-purple'],
    foreground: '#fff',
  },
  'east-bay': {
    background: dark['east-bay'],
    foreground: '#fff',
  },
  'butterfly-bush': {
    background: dark['butterfly-bush'],
    foreground: '#fff',
  },
  scampi: {
    background: dark.scampi,
    foreground: '#fff',
  },
  'wild-blue-yonder': {
    background: dark['wild-blue-yonder'],
    foreground: '#000',
  },
  'blue-bell': {
    background: dark['blue-bell'],
    foreground: '#000',
  },
  logan: {
    background: dark.logan,
    foreground: '#000',
  },
  'lavender-gray': {
    background: dark['lavender-gray'],
    foreground: '#000',
  },
  snuff: {
    background: dark.snuff,
    foreground: '#000',
  },
  'snuff-light': {
    background: dark['snuff-light'],
    foreground: '#000',
  },
};

const successPallet = {
  killarney: {
    background: dark.killarney,
    foreground: '#fff',
  },
  goblin: {
    background: dark.goblin,
    foreground: '#fff',
  },
  'fruit-salad': {
    background: dark['fruit-salad'],
    foreground: '#000',
  },
  fern: {
    background: dark.fern,
    foreground: '#000',
  },
  'de-york': {
    background: dark['de-york'],
    foreground: '#000',
  },
  'de-york-light': {
    background: dark['de-york-light'],
    foreground: '#000',
  },
  'moss-green': {
    background: dark['moss-green'],
    foreground: '#000',
  },
  'moss-green-light': {
    background: dark['moss-green-light'],
    foreground: '#000',
  },
  zanah: {
    background: dark.zanah,
    foreground: '#000',
  },
  'zanah-light': {
    background: dark['zanah-light'],
    foreground: '#000',
  },
};

const secondaryPallet = {
  'ebony-clay-dark': {
    background: dark['ebony-clay-dark'],
    foreground: '#fff',
  },
  'ebony-clay': {
    background: dark['ebony-clay'],
    foreground: '#fff',
  },
  'oxford-blue': {
    background: dark['oxford-blue'],
    foreground: '#fff',
  },
  fiord: {
    background: dark.fiord,
    foreground: '#fff',
  },
  'blue-bayoux': {
    background: dark['blue-bayoux'],
    foreground: '#fff',
  },
  lynch: {
    background: dark.lynch,
    foreground: '#000',
  },
  'bali-hai': {
    background: dark['bali-hai'],
    foreground: '#000',
  },
  'gull-gray': {
    background: dark['gull-gray'],
    foreground: '#000',
  },
  heather: {
    background: dark.heather,
    foreground: '#000',
  },
  ghost: {
    background: dark.ghost,
    foreground: '#000',
  },
};

const darkPallet = {
  primary: primaryPallet,
  secondary: secondaryPallet,
  success: successPallet,
  danger: dangerPallet,
  warning: warningPallet,
  info: infoPallet,
};

export default darkPallet;
