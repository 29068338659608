import React from 'react';
import styled from 'styled-components';
import ParagraphBlock from './ParagraphBlock';
import ImageBlock from './ImageBlock';
import { constants } from '../../shared';

const StyledMediaBlock = styled.div`
  display: flex;
  width: ${({ width }: { width: string }) => width};
`;

const StyledMediaBlockImage = styled(ImageBlock)`
  margin-right: 10px;
`;

type MediaBlockProps = {
  profileImageSize?: keyof typeof constants.sizes;
  square?: boolean;
  rows?: number;
  width?: string;
  lineWidths?: number[];
  lineHeight?: string;
} & React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const MediaBlock = ({
  profileImageSize,
  square,
  rows,
  width,
  lineHeight,
  lineWidths,
  ...props
}: MediaBlockProps): JSX.Element => {
  return (
    <StyledMediaBlock width={width} {...props}>
      <StyledMediaBlockImage size={profileImageSize} square={square} />
      <ParagraphBlock rows={rows} lineHeight={lineHeight} lineWidths={lineWidths} />
    </StyledMediaBlock>
  );
};

MediaBlock.defaultProps = {
  profileImageSize: 'xl',
  square: false,
  rows: 4,
  width: '100%',
  lineHeight: '1em',
  lineWidths: [97, 100, 94, 90, 98, 95, 98, 40],
};

export default MediaBlock;
