import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { Pagination as BSPagination } from 'react-bootstrap';
import { themes, ThemeProps } from '../../shared';
import usePagination, { PageNumbers } from './usePagination';
import ItemsPerPageSelection from './ItemsPerPageSelection';

const StyledPaginationWrapper = styled(({ theme, hideBorders, ...rest }) => <span {...rest} />)`
  .pagination {
    margin: 0;

    .page-item.active.disabled > .page-link {
      background-color: ${({ theme }) => theme.muted.primary.colour} !important;
      border-color: ${({ theme }) => theme.muted.primary.colour} !important;
    }

    ${({ hideBorders }) => hideBorders && '.page-link { border: none !important; border-radius: 5px;}'}

    .page-item {
      .page-link {
        color: ${({ theme }) => theme.standard.primary.colour} !important;
      }

      .page-link:focus {
        outline: none;
        box-shadow: 0 0 0 2px
          ${({ theme }) => (theme?.name === 'dark' ? theme?.standard.primary.accent : theme?.standard.primary.colour)};
      }

      &.active > .page-link {
        color: ${({ theme }) => theme.standard.primary.font} !important;
        background-color: ${({ theme }) => theme.standard.primary.colour} !important;
        border-color: ${({ theme }) => theme.standard.primary.colour} !important;
      }

      &.disabled > span {
        color: ${({ theme }) => theme.standard.light.accent} !important;
      }
    }
  }
`;

type PaginationProps = {
  totalItems: number;
  currentPage?: number | null | undefined;
  numberOfPagesToShow?: number;
  small?: boolean;
  hideItemsPerPageDropdown?: boolean;
  disabled?: boolean;
  itemsPerPage?: number | null | undefined;
  itemsPerPageOptions?: Array<number>;
  hideBorders?: boolean;
  hideMaxMinPageNumbers?: boolean;
  hideEllipsis?: boolean;
  handleChangeNumItemsPerPage: (size: number) => void;
  handleCurrentPageChange: (currentPage: number) => void;
} & Pick<ThemeProps, 'theme'> &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const Pagination = ({
  small,
  theme,
  numberOfPagesToShow,
  totalItems,
  disabled,
  hideItemsPerPageDropdown,
  itemsPerPageOptions,
  handleCurrentPageChange,
  handleChangeNumItemsPerPage,
  currentPage: userProvidedCurrentPage,
  itemsPerPage: userProvidedItemsPerPage,
  hideBorders,
  hideMaxMinPageNumbers,
  hideEllipsis,
}: PaginationProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalNumberOfPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);

  useEffect(() => {
    if (userProvidedCurrentPage && userProvidedCurrentPage > 0 && userProvidedCurrentPage <= totalNumberOfPages) {
      setCurrentPage(Number(userProvidedCurrentPage || 1));
    }
  }, [userProvidedCurrentPage, totalNumberOfPages]);

  useEffect(() => {
    if (itemsPerPageOptions.includes(Number(userProvidedItemsPerPage))) {
      setItemsPerPage(Number(userProvidedItemsPerPage));
    } else {
      setItemsPerPage(itemsPerPageOptions[0]);
    }
  }, [userProvidedItemsPerPage, itemsPerPageOptions]);

  const pages = usePagination({
    currentPage,
    numberOfPagesToShow,
    totalNumberOfPages,
    hideMaxMinPageNumbers,
  });

  const handleChangeItemsPerPage = (itemsPerPageSelection: number) => {
    setItemsPerPage(itemsPerPageSelection);
    handleChangeNumItemsPerPage(itemsPerPageSelection);
    handlePageChange(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    handleCurrentPageChange(newPage);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledPaginationWrapper hideBorders={hideBorders}>
        <BSPagination className={`${small ? 'pagination-sm' : ''}`}>
          <BSPagination.Prev
            disabled={currentPage === 1 || disabled}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {pages.map((page: PageNumbers) => {
            return (
              <React.Fragment key={`${page.type}-${page.pageNumber}`}>
                {page.type === 'prevEllipsis' && !hideEllipsis && (
                  <BSPagination.Ellipsis
                    disabled={disabled}
                    onClick={() => handlePageChange(pages[!hideMaxMinPageNumbers ? 2 : 1].pageNumber - 1)}
                  />
                )}
                {page.type === 'page' && (
                  <BSPagination.Item
                    active={currentPage === page.pageNumber}
                    onClick={() => handlePageChange(page.pageNumber)}
                    disabled={disabled}
                  >
                    {page.pageNumber}
                  </BSPagination.Item>
                )}
                {page.type === 'nextEllipsis' && !hideEllipsis && (
                  <BSPagination.Ellipsis
                    disabled={disabled}
                    onClick={() =>
                      handlePageChange(
                        pages[!hideMaxMinPageNumbers ? pages.length - 3 : pages.length - 2].pageNumber + 1,
                      )
                    }
                  />
                )}
              </React.Fragment>
            );
          })}
          <BSPagination.Next
            disabled={currentPage === totalNumberOfPages || !totalNumberOfPages || disabled}
            onClick={() => handlePageChange(currentPage + 1)}
          />

          {!hideItemsPerPageDropdown ? (
            <li>
              <ItemsPerPageSelection
                disabled={disabled}
                currentItemsPerPageSelection={itemsPerPage}
                itemsPerPageOptions={itemsPerPageOptions.map((option: string | number) => +option)}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
              />
            </li>
          ) : null}
        </BSPagination>
      </StyledPaginationWrapper>
    </ThemeProvider>
  );
};

Pagination.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  numberOfPagesToShow: 5,
  currentPage: null,
  itemsPerPage: null,
  disabled: false,
  hideItemsPerPageDropdown: false,
  small: false,
  itemsPerPageOptions: [10, 20, 50, 100],
  hideBorders: false,
  hideMaxMinPageNumbers: false,
  hideEllipsis: false,
};

const ThemedPagination = withTheme(Pagination);
ThemedPagination.displayName = 'WithTheme(Pagination)';
export default ThemedPagination;
